import { UseMutationResult, useMutation, useQuery } from "@tanstack/react-query";
import { publicApiEndpoint } from "../config/config";
import { ResGetTicket, ResLoginTicket } from "../models/formSearchTicket.model";
import { CampiTicket, CustomerTicketReqBody, ResPostTicket, RetailerTicketReqBody, Status } from "../models/ticket.model";
import { fetchJson } from "./http.service";
import { ContactUs } from "../models/ContactUs.model";

const loginTicket = (email: string, code: number, captchaToken: string) => fetchJson<ResLoginTicket>(publicApiEndpoint + '/assistenza/ticket', {
  headers: {
    'Content-Type': 'application/json'
  },
  method: 'POST',
  body: JSON.stringify({
    email,
    code,
    captchaToken
  })
});

const getTicket = (token: string): Promise<ResGetTicket> => fetchJson<ResGetTicket>(publicApiEndpoint + '/assistenza/ticket/' + token);

// export function useGetTicket({ email, code }: { email?: string, code?: string }) {
//   return useQuery<ResSearchTicket>({
//     queryKey: ['ticket', email, code],
//     queryFn: (context) => {
//       if (!email || !code) {
//         throw new Error('Ticket not found')
//       }
//       return getTicket(email, code)
//         .then(one => {
//           if (!one) {
//             throw new Error('Ticket not found')
//           }
//           return one;
//         })
//     }
//   })
// }

type LoginTicketVariables = { email: string, code: number, captchaToken: string }
export function useLoginTicket() {
  return useMutation<ResLoginTicket, unknown, LoginTicketVariables>(({ email, code, captchaToken }) => {
    return loginTicket(email, code, captchaToken)
      .then(one => {
        if (!one) {
          throw new Error('Ticket not found')
        }
        return one;
      })
  }
  )
}

const initializePayment = (ticketToken: string) => fetchJson<{ url: string }>(`${publicApiEndpoint}/assistenza/ticket/${ticketToken}/payment/initialize`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    successCallback: window.location.origin + `/ticket/${ticketToken}/pagamento/success`,
    errorCallback: window.location.origin + `/ticket/${ticketToken}/pagamento/error`
  })
});
export function useInizializePayment() {
  return useMutation<{ url: string }, unknown, { ticketToken: string }>({
    mutationKey: ['initializePayment'],
    mutationFn: ({ ticketToken }) => initializePayment(ticketToken)
  })
}

export function useGetTicket(ticketToken: string) {
  return useQuery<ResGetTicket>({
    queryKey: ['ticket', ticketToken],
    queryFn: () => getTicket(ticketToken)
      .then(one => {
        if (!one) {
          throw new Error('Ticket not found')
        }
        return one;
      }),
    refetchOnMount: false,
  }
  )
}

const getStatus = () => fetch(publicApiEndpoint + '/assistenza/progress/list')
  .then(res => res.json() as Promise<Status[]>);

export function useGetStatus({ progressCode }: { progressCode?: string }, enabled: boolean) {
  return useQuery<Status>({
    queryKey: ['status', progressCode],
    enabled,
    queryFn: (context) => {
      if (!progressCode) {
        throw new Error('Status not found')
      }
      return getStatus()
        .then(one => {
          if (!one) {
            throw new Error('Status not found')
          }
          const status = one.find((status) => status.zen_desk_status === progressCode);
          if (!status) {
            throw new Error('Status not found')
          }
          return status;
        })
    }
  })
}

const getCampiTicket = (user: string) => fetch(publicApiEndpoint + '/assistenza/ticket/form/' + user)
  .then(res => res.json() as Promise<CampiTicket[]>);

export function useGetCampiTicket({ lang, user }: { lang: string, user: 'customer' | 'retailer' }) {
  return useQuery<CampiTicket>({
    queryKey: ['campi-ticket', lang, user],
    queryFn: (context) => {
      return getCampiTicket(user)
        .then(one => {
          if (!one) {
            throw new Error('Ticket not found')
          }
          const campiPerLingua = one.find((campi) => campi.country === lang);
          if (!campiPerLingua) {
            throw new Error('Ticket not found')
          }
          return campiPerLingua;
        })
    }
  })
}

const getContactUs = (user: string) => fetch(publicApiEndpoint + '/assistenza/contact-us/' + user)
  .then(res => res.json() as Promise<ContactUs[]>);

export function useGetContactUs({ lang, user }: { lang: string, user: 'customer' | 'retailer' }) {
  return useQuery<ContactUs>({
    queryKey: ['contactus', lang, user],
    queryFn: (context) => {
      return getContactUs(user)
        .then(one => {
          if (!one) {
            throw new Error('Contactus not found')
          }
          const contactUsLingua = one.find((campi) => campi.country === lang);
          if (!contactUsLingua) {
            throw new Error('Contactus not found')
          }
          return contactUsLingua;
        })
    }
  })
}

const getSignedUrl = (user: 'customer' | 'retailer', contentType: string) => fetch(publicApiEndpoint + `/assistenza/ticket/attachment/${user}/signed-url?` + new URLSearchParams({
  contentType,
}))
  .then(res => res.json() as Promise<{ url: string, path: string }>);

const uploadFile = (url: string, file: File) => 
  fetch(url, {
    method: 'PUT',
    body: file
  });


// POST ticket customer or retailer
const createTicket = (user: 'customer' | 'retailer', country: string, dataForm: CustomerTicketReqBody | RetailerTicketReqBody, captchaToken: string) =>
  fetchJson<ResPostTicket>(publicApiEndpoint + `/assistenza/ticket/${user}/${country}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      ...dataForm,
      captchaToken
    })
  });
// type CreateTicketVariables = ({ country: string, captchaToken: string, file?: File } & ({ user: 'retailer', dataForm: RetailerTicketReqBody, } | { user: 'customer', dataForm: CustomerTicketReqBody }))
type CreateTicketVariables = { country: string, captchaToken: string, file?: File,  user: 'customer' | 'retailer', dataForm: CustomerTicketReqBody |RetailerTicketReqBody }
export function useCreateTicket(): UseMutationResult<ResPostTicket, unknown, CreateTicketVariables, unknown> {
  return useMutation<ResPostTicket, unknown, CreateTicketVariables>(async ({ user, country, dataForm, captchaToken, file }) => {
    if (file) {
      const signedUrl = await getSignedUrl(user, file.type);
      await uploadFile(signedUrl.url, file);
      dataForm = {
        ...dataForm,
        attachments: Array(signedUrl.path)
        }
      }
    const one = await createTicket(user, country, dataForm, captchaToken);
    if (!one) {
      throw new Error('Create Ticket not work');
    }
    return one;
  }
  )
}

