import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Fragment, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { LangContext } from "../contexts/lang.context";
import { Category } from "../models/Category.model";
import { Faq } from "../models/faq.model";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1D4EBB",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#FFFFFF",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Row(props: { row: Faq }) {
  const { row } = props;
  const { lang } = useContext(LangContext);
  const [open, setOpen] = useState(false);
  // const { lang } = useContext(LangContext);
  return (
    <Fragment>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell align="left">
          <p className="text-lg">{row.translations[lang].title}</p>
        </StyledTableCell>
        <StyledTableCell align="right">
          <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#F5F5F5",
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {" "}
              <Typography variant="h6" gutterBottom component="div">
                {row.translations[lang].subtitle}
              </Typography>
              <div
                dangerouslySetInnerHTML={{
                  __html: row.translations[lang].body,
                }}
              ></div>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </Fragment>
  );
}

export default function TableFAQ({
  faqs,
  categories,
}: {
  faqs: Faq[];
  categories: Category[];
}) {
  const [categoriesUidChecked, setCategoriesUidChecked] = useState<string[]>(
    []
  );
  const [filteredFaqs, setFilteredFaqs] = useState<Faq[]>([]);
  const { lang } = useContext(LangContext);

  const handleToggle = (value: Category["uid"]) => () => {
    if (categoriesUidChecked.includes(value)) {
      setCategoriesUidChecked(
        categoriesUidChecked.filter((el) => el !== value)
      );
    } else {
      setCategoriesUidChecked([...categoriesUidChecked, value]);
    }
  };

  const matrixCategories = faqs
    .filter((faq) => faq.published === true)
    .map((faq) =>
      faq.categories.map((faqCat) =>
        categories.find((cat) => cat.uid === faqCat)
      )
    );

  const allCategories = new Set<Category>();
  for (const arrayCategory of matrixCategories) {
    for (const categorie of arrayCategory) {
      if (categorie) allCategories.add(categorie);
    }
  }

  useEffect(() => {
    if (categories) {
      const uniqueCategories: string[] = [];
      for (let index = 0; index < faqs.length; index++) {
        const faq = faqs[index];
        const currCategories = faq.categories;
        for (let x = 0; x < currCategories.length; x++) {
          const categoryUID = currCategories[x];
          if (!uniqueCategories.includes(categoryUID)) {
            uniqueCategories.push(categoryUID);
          }
        }
      }
      setCategoriesUidChecked(uniqueCategories);
    }
  }, [categories, faqs]);
  faqs = faqs.filter((faq) => faq.published === true);
  useEffect(() => {
    let fawsFilteredByCategories: Faq[] = [];
    for (let index = 0; index < faqs.length; index++) {
      const currFaq = faqs[index];
      for (let x = 0; x < categoriesUidChecked.length; x++) {
        const currCategory = categoriesUidChecked[x];
        if (
          currFaq.categories.includes(currCategory) &&
          !fawsFilteredByCategories.find((el) => el.uid === currFaq.uid)
        ) {
          fawsFilteredByCategories.push(currFaq);
        }
      }
    }
    setFilteredFaqs(fawsFilteredByCategories);
  }, [categoriesUidChecked, faqs]);
  return (
    <div className="grid xl:grid-cols-5 gap-4 grid-cols-1">
      <div className="col-span-1">
        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          subheader={
            <ListSubheader
              sx={{ background: "var(--theme-color)", color: "#FFFFFF" }}
            >
              <FormattedMessage
                id="table-faq_select_categories"
                defaultMessage="Categoria:"
                description="TableFaq Categoria:"
              />
            </ListSubheader>
          }
        >
          {Array.from(allCategories).map((value) => {
            return (
              <ListItem
                key={value.uid}
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(value.uid)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={categoriesUidChecked.includes(value.uid)}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={value.uid}
                    primary={value.translation[lang]}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </div>
      <div className="sm:col-span-1 xl:col-span-4">
        <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="left">FAQS</StyledTableCell>
                <StyledTableCell align="right" />
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {filteredFaqs.map((row) => (
                <Row key={row.uid} row={row} />
              ))}
              {filteredFaqs.length === 0 && (
                <Fragment>
                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        paddingBottom: 10,
                        paddingTop: 10,
                        backgroundColor: "#F5F5F5",
                      }}
                      colSpan={6}
                      align="center"
                    >
                      No Faqs
                    </StyledTableCell>
                  </StyledTableRow>
                </Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
