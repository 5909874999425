import { useQuery } from "@tanstack/react-query";
import { publicApiEndpoint } from "../config/config";
import { Manual } from "../models/manual.model";

const getManuals = (uid: string) => fetch(publicApiEndpoint + `/assistenza/device/${uid}/download/list`)
  .then(res => res.json() as Promise<Manual[]>);

export function useGetManuals({ uid }: { uid: string |undefined }) {
  if (uid === undefined) {
    throw new Error('Manual not found')
  }
  return useQuery<Manual[]>({
    queryKey: ['manual', uid],
    queryFn: () =>
    getManuals(uid)
        .then(one => {
          if (!one) {
            throw new Error('Manual not found')
          }
          return one;
        })
  })
}