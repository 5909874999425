import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { PagamentoCard } from "../../components/PagamentoCard";
import { PageError } from "../../components/PageError";
import { useGetTicket } from "../../services/ticket.service";

export default function PagamentoTicket() {
  const { ticketToken } = useParams();
  const { data, isLoading, isError } = useGetTicket(ticketToken!);

  return (
    <div className="contenitore-pagina-pagamento box-form container mx-auto my-10 py-4 px-8">
      {isLoading && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {!isLoading && isError && (
        <div className="page-loading">
          <PageError errorMessage="Error" />
        </div>
      )}
      {data && <PagamentoCard ticket={data!} ticketToken={ticketToken!} />}
    </div>
  );
}
