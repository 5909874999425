import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import FaxIcon from "@mui/icons-material/Fax";
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useGetContactUs } from "../services/ticket.service";
import { Rivenditore } from "../models/rivenditore.model";
import { useContext } from "react";
import { LangContext } from "../contexts/lang.context";
import { PageError } from "./PageError";

interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

export function ContactDialog(props: SimpleDialogProps) {
  const { onClose, open } = props;
  const { lang } = useContext(LangContext);
  const rivenditoreSession = sessionStorage.getItem("rivenditore");
  let rivenditore: Rivenditore | undefined = undefined;
  if (rivenditoreSession !== null) {
    try {
      rivenditore = JSON.parse(rivenditoreSession);
    } catch (error) {}
  }

  const user = !!rivenditore ? "retailer" : "customer";
  const {
    data: contactUs,
    error,
    isLoading,
  } = useGetContactUs({
    lang,
    user,
  });

  return (
    <>
      {isLoading && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {!isLoading && !!error && (
        <div className="page-loading">
          <PageError errorMessage="Error" />
        </div>
      )}
      {!isLoading && contactUs && (
        <Dialog onClose={onClose} open={open}>
          <DialogTitle className="background-blue-clem text-white">
            <FormattedMessage
              id="select-device_menu_contactus"
              defaultMessage="Contatti"
              description="DeviceCard Contatti"
            />
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-header">
              <div
                dangerouslySetInnerHTML={{
                  __html: contactUs.header || "",
                }}
              ></div>
            </DialogContentText>
            {contactUs.phoneVisible && (
              <Typography gutterBottom>
                <div className="px-2 py-2">
                  <p className="font-semibold">
                    <FormattedMessage
                      id="faq_popup_phone_phone_text"
                      defaultMessage="Telefono"
                      description="DeviceCard Telefono"
                    />
                  </p>
                  <LocalPhoneIcon sx={{ color: "#757575", marginRight: 1 }} />
                  <a href={contactUs.phone}>
                    {contactUs.phone}
                  </a>
                  <p>{contactUs.phoneMessage}</p>
                </div>
              </Typography>
            )}
            {contactUs.mailVisible && (
              <Typography gutterBottom>
                <div className="px-2 py-2">
                  <p className="font-semibold">Email</p>
                  <EmailIcon sx={{ color: "#757575", marginRight: 1 }} />
                  <a href={"mailto:" + contactUs.mail}>{contactUs.mail}</a>
                </div>
              </Typography>
            )}
            {contactUs.faxVisible && (
              <Typography gutterBottom>
                <div className="px-2 py-2">
                  <p className="font-semibold">Fax</p>
                  <FaxIcon sx={{ color: "#757575", marginRight: 1 }} />
                  <a href={"unsafe:fax:" + contactUs.fax}>{contactUs.fax}</a>
                </div>
              </Typography>
            )}
            <DialogContentText id="alert-dialog-footer">
              <div
                className="mt-2"
                dangerouslySetInnerHTML={{
                  __html: contactUs.footerMessage || "",
                }}
              ></div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
