import HCaptcha from "@hcaptcha/react-hcaptcha";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { ChangeEvent, useContext, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { PageError } from "../../components/PageError";
import { LangContext } from "../../contexts/lang.context";
import { RetailerReqBody } from "../../models/rivenditore.model";
import { useCateneCommerciali } from "../../services/catenaCommerciale.service";
import { nazioni, province } from "../../services/nazioniEprovince";
import { useCreateRetailer } from "../../services/rivenditore.service";
import { MenuProps } from "../Ticket-Assistenza/TicketAssistenza";

export default function Registrazione() {
  const { lang } = useContext(LangContext);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const captchaRef = useRef<HCaptcha>(null);
  const [hCaptchaToken, setHCaptchaToken] = useState<undefined | string>(
    undefined
  );

  const {
    data: cateneCommerciali,
    error,
    isLoading,
  } = useCateneCommerciali({ lang });

  // Setter variabili form
  const [nazione, setNazione] = useState<string | undefined>();
  const nazioneSelected = useMemo(
    () => nazioni?.find((one) => nazione === one.code),
    [nazione]
  );
  const [provincia, setProvincia] = useState<string | undefined>();
  const provinciaSelected = useMemo(
    () => province?.find((one) => provincia === one.code),
    [provincia]
  );
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const handleInputChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckbox(event.target.checked);
  };
  const [storeBrandId, setcatenaComm] = useState("");
  const handleChangeCatenaComm = (event: SelectChangeEvent) => {
    setcatenaComm(event.target.value as string);
  };
  const [storeName, setStoreName] = useState("");
  const handleChangeStoreName = (event: ChangeEvent<HTMLInputElement>) => {
    setStoreName(event.target.value);
  };
  const [address1, setAddress1] = useState("");
  const handleChangeAddress1 = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress1(event.target.value);
  };
  const [address2, setAddress2] = useState("");
  const handleChangeAddress2 = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress2(event.target.value);
  };
  const [vat, setVat] = useState("");
  const handleChangeVat = (event: ChangeEvent<HTMLInputElement>) => {
    setVat(event.target.value);
  };
  const [phone, setPhone] = useState("");
  const handleChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };
  const [email, setEmail] = useState("");
  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const [postCode, setCap] = useState("");
  const handleChangeCap = (event: ChangeEvent<HTMLInputElement>) => {
    setCap(event.target.value);
  };
  const [city, setCitta] = useState("");
  const handleChangeCitta = (event: ChangeEvent<HTMLInputElement>) => {
    setCitta(event.target.value);
  };

  const isFormValid = storeName !== "" && nazioneSelected?.code !== "" && vat !== "" && address1 !== "" && postCode !== "" && email !== "" && phone !== "" && 
    city !== "";

  // POST registrazione
  const {
    mutateAsync,
    isLoading: isLoadingCreateRetailer,
    isError,
  } = useCreateRetailer();

  function createRetailer(): void {
    if (!hCaptchaToken) {
      return;
    }
    const dataForm: RetailerReqBody = {
      storeBrandId: storeBrandId,
      storeName: storeName,
      country: nazioneSelected!.code.toLocaleLowerCase(),
      address1: address1,
      address2: address2,
      vat: vat,
      phone: phone,
      email: email,
      postCode: postCode,
      province: provinciaSelected?.code,
      city: city,
    };
    mutateAsync({
      dataForm,
      captchaToken: hCaptchaToken,
    })
      .then((res) => {
        navigate("/registrazione/inviata");
      })
      .catch((e) => {
        captchaRef.current?.resetCaptcha();
        console.error(e);
      });
  }

  return (
    <>
      {isLoading && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {!isLoading && !!error && (
        <div className="page-loading">
          <PageError errorMessage="Error" />
        </div>
      )}
      {cateneCommerciali && (
        <div className="container mx-auto px-4 contenitore-registrazione">
          <div className="box-form  my-10 py-4 px-8 bg-[#FFFFFF] shadow-xl">
            <div>
              <Link to=".." relative="path">
                <Button className="buttons-style" variant="contained">
                  <ArrowCircleLeftIcon className="icon-button" />{" "}
                  <span className="w-full">
                    <FormattedMessage
                      id="aftersale_backlink"
                      defaultMessage="Torna indietro"
                      description="Registrazione Torna indietro"
                    />
                  </span>
                </Button>
              </Link>

              <p className="text-2xl">
                <FormattedMessage
                  id="formretailer_register_title"
                  defaultMessage="Registrazione rivenditore"
                  description="Registrazione Registrazione rivenditore"
                />
              </p>
              <p>
                <FormattedMessage
                  id="formretailer_register_description"
                  defaultMessage="!!! ATTENZIONE !!! L'indirizzo sarà utilizzato per il ritiro e la restituzione del prodotto mentre l'email verrà utilizzata per eventuali comunicazioni."
                  description="Registrazione !!! ATTENZIONE !!!"
                />
              </p>
              <p className="text-right">
                <FormattedMessage
                  id="retail_logged_contact_required"
                  defaultMessage="*campi obbligatori"
                  description="Registrazione *campi obbligatori"
                />
              </p>
              <p className="font-semibold">
                <FormattedMessage
                  id="field_storebrand"
                  defaultMessage="Inserire il nome della catena commerciale"
                  description="Registrazione Inserire il nome della catena commerciale"
                />
              </p>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small-label">
                  <FormattedMessage
                    id="faq_popup_contact_storebrand"
                    defaultMessage="Nome catena commerciale"
                    description="Registrazione Nome catena commerciale"
                  />
                </InputLabel>
                <Select
                  error={isError && storeBrandId === ""}
                  required
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  name="nomeCatenaCommerciale"
                  MenuProps={MenuProps}
                  value={storeBrandId}
                  label={
                    <FormattedMessage
                      id="faq_popup_contact_storebrand"
                      defaultMessage="Nome catena commerciale"
                      description="Registrazione Nome catena commerciale"
                    />
                  }
                  onChange={handleChangeCatenaComm}
                >
                  {cateneCommerciali?.map((catena) => (
                    <MenuItem value={catena.uid}>{catena.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="grid gap-4 grid-cols-2">
              <div>
                <p className="font-semibold">
                  <FormattedMessage
                    id="field_storename"
                    defaultMessage="Inserisci il nome del negozio"
                    description="Registrazione Inserisci il nome del negozio"
                  />
                  *
                </p>
                <TextField
                  error={isError && storeName === ""}
                  fullWidth
                  label="Nome negozio"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="storeName"
                  value={storeName}
                  onChange={handleChangeStoreName}
                />
                <p className="font-semibold">
                  <FormattedMessage
                    id="field_address1"
                    defaultMessage="Indirizzo sede operativa"
                    description="Registrazione Indirizzo sede operativa"
                  />
                  *
                </p>
                <p>
                  <TextField
                    error={isError && address1 === ""}
                    fullWidth
                    label="Indirizzo ritiro e riconsegna prodotto"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    type="text"
                    name="address1"
                    value={address1}
                    onChange={handleChangeAddress1}
                  />
                </p>
                <TextField
                  fullWidth
                  label="Indirizzo ritiro e riconsegna prodotto"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="address2"
                  value={address2}
                  onChange={handleChangeAddress2}
                />
                <div className="grid gap-4 grid-cols-2">
                  <div>
                    <p className="font-semibold">
                      <FormattedMessage
                        id="field_postcode"
                        defaultMessage="Codice postale"
                        description="Registrazione Codice postale"
                      />
                      *
                    </p>
                    <TextField
                      error={isError && postCode === ""}
                      fullWidth
                      label="CAP"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="postCode"
                      value={postCode}
                      onChange={handleChangeCap}
                      inputProps={{ minLength: 1, maxLength: 10 }}
                    />
                  </div>
                  <div>
                    <p className="font-semibold">
                      <FormattedMessage
                        id="faq_popup_contact_province"
                        defaultMessage="Provincia"
                        description="TicketAssistenza Provincia"
                      />
                    </p>
                    <Autocomplete
                      disablePortal
                      fullWidth
                      id="combo-box-demo"
                      size="small"
                      options={province}
                      value={provinciaSelected}
                      getOptionLabel={(option) => option.label}
                      onChange={(e, value) => setProvincia(value?.code)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </div>
                </div>
                <p className="font-semibold">
                  <FormattedMessage
                    id="field_city"
                    defaultMessage="Città"
                    description="Registrazione Città"
                  />
                  *
                </p>
                <TextField
                  error={isError && city === ""}
                  fullWidth
                  label="Città"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="city"
                  value={city}
                  onChange={handleChangeCitta}
                  inputProps={{ minLength: 1, maxLength: 40 }}
                />
                <p className="font-semibold">
                  <FormattedMessage
                    id="nazione-forms"
                    defaultMessage="Nazione"
                    description="TicketAssistenza Nazione"
                  />
                  *
                </p>
                <Autocomplete
                  disablePortal
                  fullWidth
                  id="autocomplete-nazioni"
                  size="small"
                  options={nazioni}
                  value={nazioneSelected}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => setNazione(value?.code)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <div>
                <p className="font-semibold">
                  <FormattedMessage
                    id="faq_popup_contact_vatnumber"
                    defaultMessage="Partita IVA"
                    description="Registrazione Partita IVA"
                  />
                  *
                </p>
                <TextField
                  error={isError && vat === ""}
                  fullWidth
                  label="Partita IVA"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="vat"
                  value={vat}
                  onChange={handleChangeVat}
                  inputProps={{ minLength: 1, maxLength: 30 }}
                />
                <p className="font-semibold">
                  <FormattedMessage
                    id="retail_logged_phone_phone_text"
                    defaultMessage="Telefono"
                    description="Registrazione Telefono"
                  />
                  *
                </p>
                <TextField
                  error={isError && phone === ""}
                  fullWidth
                  label="Telefono"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={handleChangePhone}
                  inputProps={{ minLength: 5, maxLength: 30 }}
                />
                <p className="font-semibold">
                  <FormattedMessage
                    id="retail_logged_phone_email_text"
                    defaultMessage="Email"
                    description="Registrazione Email"
                  />
                  *
                </p>
                <TextField
                  error={isError && email === ""}
                  fullWidth
                  label="Email"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="email"
                  value={email}
                  onChange={handleChangeEmail}
                  inputProps={{ minLength: 1, maxLength: 100 }}
                />
                <FormGroup>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        name="checkbox"
                        value={checkbox}
                        onChange={handleInputChangeCheckbox}
                      />
                    }
                    label={
                      <p
                        className="font-semibold"
                        dangerouslySetInnerHTML={{
                          __html: formatMessage({
                            id: "faq_popup_contact_privacy",
                            defaultMessage:
                              "Letta l’informativa, con riferimento al trattamento dei dati a fini di gestione delle richieste da parte dei clienti - Finalità 1 dell’informativa\n<a href='http://clempad.clementoni.com/pdf/privacy_policy_it_IT.pdf' target='_blank'>informativa</a>",
                            description: "TicketAssistenza Letta l’informativa",
                          }),
                        }}
                      ></p>
                    }
                  />
                </FormGroup>
                <p className="text-red-600" hidden={checkbox}>
                  <FormattedMessage
                    id="form_required_field"
                    defaultMessage="Il campo è obbligatorio"
                    description="Registrazione Il campo è obbligatorio"
                  />
                </p>
                <Button
                  disabled={!hCaptchaToken || !checkbox || !isFormValid || isLoadingCreateRetailer}
                  className="buttons-style"
                  sx={{
                    "&.Mui-disabled": {
                      backgroundColor: '#b3b3b3 !important',
                      color: 'white',
                    }
                  }}
                  variant="contained"
                  onClick={() => createRetailer()}
                >
                  {isLoadingCreateRetailer && <CircularProgress size={24} />}
                  {!isLoadingCreateRetailer && (
                    <FormattedMessage
                      id="fyr-btn-find"
                      defaultMessage="Visualizza la richiesta"
                      description="SearchTicket Visualizza la richiesta"
                    />
                  )}
                </Button>
                {isError && (
                  <p className="text-red-600">
                    <FormattedMessage
                      id="errore-invio"
                      defaultMessage="Errore invio"
                      description="TicketAssistenza messaggio errore invio"
                    />
                  </p>
                )}
                <div className="mt-2">
                  <HCaptcha
                    ref={captchaRef}
                    sitekey={process.env.REACT_APP_hCaptchaKey as string}
                    onVerify={setHCaptchaToken}
                    onExpire={() => setHCaptchaToken(undefined)}
                  ></HCaptcha>
                </div>
              </div>
            </div>
            <div className="info-registrazione">
              <p>
                <FormattedMessage
                  id="register_retailer_message1"
                  defaultMessage="La richiesta di registrazione verrà presa in carico da un nostro agente. Validato l'account riceverà conferma con le procedure Clementoni."
                  description="Registrazione La richiesta di registrazione"
                />
              </p>
              <p>
                <FormattedMessage
                  id="register_retailer_message2"
                  defaultMessage="La sua richiesta verrà processata in base alla priorità acquisita."
                  description="Registrazione La sua richiesta verrà processata in base alla priorità acquisita"
                />
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
