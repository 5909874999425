import HCaptcha from "@hcaptcha/react-hcaptcha";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import { ChangeEvent, Dispatch, FormEvent, SetStateAction, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { useLoginTicket } from "../../services/ticket.service";

export default function PreviewPagamentoTicket() {
  const { ticketId } = useParams();

  const [email, setEmail] = useState("");
  const captchaRef = useRef<HCaptcha>(null);
  const [hCaptchaToken, setHCaptchaToken] = useState<undefined | string>(
    undefined
  );
  const navigate = useNavigate();
  const { isLoading, mutateAsync, isError } = useLoginTicket();
  const onChangeField =
    (setter: Dispatch<SetStateAction<any>>) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setter(e.target.value);
    };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!hCaptchaToken) {
      return;
    }
    mutateAsync({
      email,
      code: parseInt(ticketId!),
      captchaToken: hCaptchaToken,
    })
      .then((res) => {
        navigate("/ticket/" + res.ticketToken + "/pagamento");
      })
      .catch((e) => {
        captchaRef.current?.resetCaptcha();
        console.error(e);
      });
  };
  return (
    <div className="box-form container my-10 mx-auto contenitore-pagina-preview-pagamento flex flex-col items-center justify-center">
      <div className="box-pagamento flex flex-col items-center shadow-lg my-4">
        <form onSubmit={handleSubmit}>
          <div>
            <p className="font-semibold">
              <FormattedMessage
                id="paywall_insert_email"
                defaultMessage="Inserisci la tua Email per verificare il pagamento"
                description="messaggio che invita l'utente a specificare la mail per visualizzare informazioni di pagamento"
              />
            </p>
            <FormControl sx={{ paddingBottom: 2 }}>
              <TextField
                fullWidth
                label="Email"
                id="outlined-basic"
                variant="outlined"
                size="small"
                type="email"
                name="email"
                value={email}
                onChange={onChangeField(setEmail)}
              />
            </FormControl>
            <HCaptcha
              ref={captchaRef}
              sitekey={process.env.REACT_APP_hCaptchaKey as string}
              onVerify={setHCaptchaToken}
              onExpire={() => setHCaptchaToken(undefined)}
            ></HCaptcha>
            <Button
              className="buttons-style"
              variant="contained"
              type="submit"
              disabled={hCaptchaToken === undefined}
              sx={{
                "&.Mui-disabled": {
                  backgroundColor: "#b3b3b3 !important",
                  color: "white",
                },
                marginTop: 2,
              }}
            >
              {isLoading && <CircularProgress size={30} />}
              {!isLoading && (
                <FormattedMessage
                  id="visualizza-pagamento"
                  defaultMessage="Visualizza la richiesta"
                  description="Bottone per visualizzare la richiesta di pagamento"
                />
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
