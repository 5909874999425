import { useQuery } from "@tanstack/react-query";
import { publicApiEndpoint } from "../config/config";
import { SpecificationsIssue } from "../models/specifiche.model";

const getSpecifiche = () => fetch(publicApiEndpoint + '/assistenza/specifications')
  .then(res => res.json() as Promise<SpecificationsIssue[]>);

export function useGetSpecifiche() {
  return useQuery<SpecificationsIssue[]>({
    queryKey: ['specifiche'],
    queryFn: (context) => {
      return getSpecifiche()
        .then(one => {
          if (!one) {
            throw new Error('Specifiche not found')
          }
          return one;
        })
    }
  })
}
