import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Divider, ListItem, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { format, parse, parseISO } from "date-fns";
import { Fragment, useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { DATE_FORMAT } from "../../App";
import LinearProgressWithLabel from "../../components/LinearProgress";
import { PagamentoCard } from "../../components/PagamentoCard";
import { PageError } from "../../components/PageError";
import { LangContext } from "../../contexts/lang.context";
import { useGetSpecifiche } from "../../services/specifiche.service";
import { useGetStatus, useGetTicket } from "../../services/ticket.service";
import { Issue } from "../../models/specifiche.model";

export default function Ticket() {
  const { ticketToken } = useParams();
  const { lang } = useContext(LangContext);
  const { data, isLoading, error } = useGetTicket(ticketToken!);
  const {
    data: status,
    error: errStatus,
    isLoading: isLoadingStatus,
  } = useGetStatus(
    {
      progressCode: data?.ticketState,
    },
    !!data?.ticketState
  );

  const {
    data: specifiche,
    error: errSpec,
    isLoading: isLoadingSpec,
  } = useGetSpecifiche();

  const problema = useMemo(
    () =>
      specifiche
        ?.reduce((issues, current) => {
          issues.push(...current.issues);
          return issues;
        }, [] as Issue[])
        .find((issue) => issue.zendesk_issue === data?.detail?.issue_id)
        ?.translations[lang],
    [data?.detail?.issue_id, lang, specifiche]
  );

  let dateShipment: string;
  try {
    if (data) {
      dateShipment = format(
        parse(data.detail.Shipment_date, "yyyyMMdd", new Date()),
        DATE_FORMAT
      );
    } else {
      dateShipment = "";
    }
  } catch (error) {
    dateShipment = "";
  }

  const isLoadingGeneral = isLoading || isLoadingStatus || isLoadingSpec;
  const errorGeneral = error || errStatus || errSpec;

  return (
    <>
      {isLoadingGeneral && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {!isLoadingGeneral && !!errorGeneral && (
        <div className="page-loading">
          <PageError errorMessage="Error" />
        </div>
      )}
      {data && status && specifiche && (
        <>
          <div className="bg-[#FFFFFF] shadow-lg">
            <div className="container mx-auto py-4 px-4">
              <Link to="/" relative="path">
                <Button className="buttons-style" variant="contained">
                  <ArrowCircleLeftIcon className="icon-button" />{" "}
                  <span className="w-full">
                    <FormattedMessage
                      id="aftersale_backlink"
                      defaultMessage="Torna indietro"
                      description="Ticket Torna indietro"
                    />
                  </span>
                </Button>
              </Link>
              <p className="mt-4 text-2xl font-semibold">
                <FormattedMessage
                  id="aftersale_title"
                  defaultMessage="Informazioni di contatto"
                  description="Ticket Informazioni di contatto"
                />
              </p>
              <p>{data.name}</p>
              <p className="font-light">
                {data.address} - {data.province} ({data.country})
              </p>
            </div>
          </div>
          <div>
            <div className="container mx-auto py-10 px-4 contenitore-ticket">
              <p className="text-2xl font-semibold">
                <FormattedMessage
                  id="aftersale_ticket_status_title"
                  defaultMessage="Stato del ticket di assistenza"
                  description="Ticket Stato del ticket di assistenza"
                />
                : {status.translations[lang]}
              </p>
              {/* barra */}
              <div className="my-4 rounded-2xl shadow-xl">
                <LinearProgressWithLabel
                  percentuale={status.value}
                  stato={status.translations[lang]}
                  colore={status.progress_bar.color}
                />
              </div>
              <div className="grid gap-8 xl:grid-cols-2 xl:grid-rows-1 grid-cols-1 grid-rows-2">
                {
                  // Dettagli della richiesta di assistenza
                }
                <div className="my-4 bg-[#FFFFFF] shadow-xl">
                  <List
                    className="ticket-h-table"
                    sx={{
                      overflowY: "auto",
                    }}
                    subheader={
                      <ListSubheader
                        sx={{
                          background: "var(--theme-color)",
                          color: "#FFFFFF",
                        }}
                      >
                        <FormattedMessage
                          id="aftersale_request_details"
                          defaultMessage="Dettagli della richiesta di assistenza"
                          description="Ticket Dettagli della richiesta di assistenza"
                        />
                      </ListSubheader>
                    }
                  >
                    <Divider />
                    <ListItem>
                      <div className="w-full grid grid-cols-2 grid-rows-1">
                        <div>
                          <p className="text-sm font-medium">
                            <FormattedMessage
                              id="Clementoni_request_ID"
                              defaultMessage="ID Clementoni"
                              description="Ticket ID Clementoni"
                            />
                          </p>
                        </div>
                        <div>
                          <p className="text-sm">
                            {data.detail.Clementoni_request_ID
                              ? data.detail.Clementoni_request_ID
                              : ""}
                          </p>
                        </div>
                      </div>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <div className="w-full grid grid-cols-2 grid-rows-1">
                        <div>
                          <p className="text-sm font-medium">
                            <FormattedMessage
                              id="RMA"
                              defaultMessage="RMA"
                              description="Ticket RMA"
                            />
                          </p>
                        </div>
                        <div>
                          <p className="text-sm">{data.detail.RMA}</p>
                        </div>
                      </div>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <div className="w-full grid grid-cols-2 grid-rows-1">
                        <div>
                          <p className="text-sm font-medium">
                            <FormattedMessage
                              id="Your_internal_reference"
                              defaultMessage="Referente postvendita"
                              description="Ticket Referente postvendita"
                            />
                          </p>
                        </div>
                        <div>
                          <p className="text-sm">
                            {data.detail.Your_internal_reference}
                          </p>
                        </div>
                      </div>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <div className="w-full grid grid-cols-2 grid-rows-1">
                        <div>
                          <p className="text-sm font-medium">
                            <FormattedMessage
                              id="Model_of_product"
                              defaultMessage="Modello prodotto"
                              description="Ticket Modello prodotto"
                            />
                          </p>
                        </div>
                        <div>
                          <p className="text-sm">{data.device.title}</p>
                        </div>
                      </div>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <div className="w-full grid grid-cols-2 grid-rows-1">
                        <div>
                          <p className="text-sm font-medium">ean</p>
                        </div>
                        <div>
                          <p className="text-sm">
                            {data.detail.Model_of_product}
                          </p>
                        </div>
                      </div>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <div className="w-full grid grid-cols-2 grid-rows-1">
                        <div>
                          <p className="text-sm font-medium">
                            <FormattedMessage
                              id="Announced_problem"
                              defaultMessage="Problema riscontrato"
                              description="Ticket Problema riscontrato"
                            />
                          </p>
                        </div>
                        <div>
                          <p className="text-sm">{problema}</p>
                        </div>
                      </div>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <div className="w-full grid grid-cols-2 grid-rows-1">
                        <div>
                          <p className="text-sm font-medium">
                            <FormattedMessage
                              id="Nature_of_the_guarantee"
                              defaultMessage="Forme di garanzia"
                              description="Ticket Forme di garanzia"
                            />
                          </p>
                        </div>
                        <div>
                          <p className="text-sm">
                            {data.detail.Nature_of_the_guarantee}
                          </p>
                        </div>
                      </div>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <div className="w-full grid grid-cols-2 grid-rows-1">
                        <div>
                          <p className="text-sm font-medium">
                            <FormattedMessage
                              id="Request_date"
                              defaultMessage="Data richiesta"
                              description="Ticket Data richiesta"
                            />
                          </p>
                        </div>
                        <div>
                          <p className="text-sm">
                            {data.detail.Request_date
                              ? format(
                                  parseISO(data.detail.Request_date),
                                  DATE_FORMAT
                                )
                              : ""}
                          </p>
                        </div>
                      </div>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <div className="w-full grid grid-cols-2 grid-rows-1">
                        <div>
                          <p className="text-sm font-medium">
                            <FormattedMessage
                              id="Reception_date"
                              defaultMessage="Data ricezione"
                              description="Ticket Data ricezione"
                            />
                          </p>
                        </div>
                        <div>
                          <p className="text-sm">
                            {data.detail.Reception_date}
                          </p>
                        </div>
                      </div>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <div className="w-full grid grid-cols-2 grid-rows-1">
                        <div>
                          <p className="text-sm font-medium">
                            <FormattedMessage
                              id="Content_of_parcel_received"
                              defaultMessage="Accessori ricevuti"
                              description="Ticket Accessori ricevuti"
                            />
                          </p>
                        </div>
                        <div>
                          <p className="text-sm">
                            {data.detail.Content_of_parcel_received}
                          </p>
                        </div>
                      </div>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <div className="w-full grid grid-cols-2 grid-rows-1">
                        <div>
                          <p className="text-sm font-medium">
                            <FormattedMessage
                              id="Repair_date"
                              defaultMessage="Data riparazione"
                              description="Ticket Data riparazione"
                            />
                          </p>
                        </div>
                        <div>
                          <p className="text-sm">{data.detail.Repair_date}</p>
                        </div>
                      </div>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <div className="w-full grid grid-cols-2 grid-rows-1">
                        <div>
                          <p className="text-sm font-medium">
                            <FormattedMessage
                              id="Shipment_date"
                              defaultMessage="Data spedizione"
                              description="Ticket Data spedizione"
                            />
                          </p>
                        </div>
                        <div>
                          <p className="text-sm">
                            {dateShipment}
                          </p>
                        </div>
                      </div>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <div className="w-full grid grid-cols-2 grid-rows-1">
                        <div>
                          <p className="text-sm font-medium">
                            <FormattedMessage
                              id="Tracking_number"
                              defaultMessage="Tracking spedizione"
                              description="Ticket Tracking spedizione"
                            />
                          </p>
                        </div>
                        <div>
                          <p className="text-sm">
                            {data.detail.Tracking_number}
                          </p>
                        </div>
                      </div>
                    </ListItem>
                  </List>
                </div>
                {
                  // Messaggi inviati
                }
                <div className="my-4 bg-[#FFFFFF] shadow-xl">
                  <List
                    className="ticket-h-table"
                    sx={{
                      overflowY: "auto",
                    }}
                    subheader={
                      <ListSubheader
                        sx={{
                          background: "var(--theme-color)",
                          color: "#FFFFFF",
                        }}
                      >
                        <FormattedMessage
                          id="aftersale_conversation_log"
                          defaultMessage="Messaggi inviati"
                          description="Ticket Messaggi inviati"
                        />
                      </ListSubheader>
                    }
                  >
                    <Divider />
                    {data.comments.map((mess) => (
                      <>
                        <ListItem>
                          <ListItemText
                            primary={`${mess.author} - ${format(
                              parseISO(mess.created_at ? mess.created_at : ""),
                              DATE_FORMAT
                            )} - -`}
                            secondary={
                              <Fragment>
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: mess.htmlBody,
                                    }}
                                  ></div>
                                </Typography>
                              </Fragment>
                            }
                          />
                        </ListItem>
                        <Divider />
                      </>
                    ))}
                  </List>
                </div>
              </div>
              {
                // Documenti
              }
              <div className="my-4 bg-[#FFFFFF] shadow-xl documenti-height">
                <List
                  subheader={
                    <ListSubheader
                      sx={{
                        background: "var(--theme-color)",
                        color: "#FFFFFF",
                      }}
                    >
                      <FormattedMessage
                        id="aftersale_documents"
                        defaultMessage="Documenti"
                        description="Ticket Documenti"
                      />
                    </ListSubheader>
                  }
                >
                  <Divider />
                  {data.documents.parcel && (
                    <>
                      <ListItemButton
                        onClick={() => window.open(data.documents.parcel!.url)}
                      >
                        <div className="w-full grid grid-cols-3 grid-rows-1">
                          <div>
                            <PictureAsPdfIcon />
                          </div>
                          <div>
                            <p className="text-center text-sm font-medium">
                              {data.documents.parcel.label}
                            </p>
                          </div>
                          <div>
                            <p className="text-right">
                              <VisibilityIcon />
                            </p>
                          </div>
                        </div>
                      </ListItemButton>
                      <Divider />
                    </>
                  )}
                  {data.documents.refund && (
                    <>
                      <ListItemButton
                        onClick={() => window.open(data.documents.refund!.url)}
                      >
                        <div className="w-full grid grid-cols-3 grid-rows-1">
                          <div>
                            <PictureAsPdfIcon />
                          </div>
                          <div>
                            <p className="text-center text-sm font-medium">
                              {data.documents.refund.label}
                            </p>
                          </div>
                          <div>
                            <p className="text-right">
                              <VisibilityIcon />
                            </p>
                          </div>
                        </div>
                      </ListItemButton>
                      <Divider />
                    </>
                  )}
                  {data.documents.invoice && (
                    <>
                      <ListItemButton
                        onClick={() => window.open(data.documents.invoice!.url)}
                      >
                        <div className="w-full grid grid-cols-3 grid-rows-1">
                          <div>
                            <PictureAsPdfIcon />
                          </div>
                          <div>
                            <p className="text-center text-sm font-medium">
                              {data.documents.invoice.label}
                            </p>
                          </div>
                          <div>
                            <p className="text-right">
                              <VisibilityIcon />
                            </p>
                          </div>
                        </div>
                      </ListItemButton>
                      <Divider />
                    </>
                  )}
                  {data.documents.report && (
                    <>
                      <ListItemButton
                        onClick={() => window.open(data.documents.report!.url)}
                      >
                        <div className="w-full grid grid-cols-3 grid-rows-1">
                          <div>
                            <PictureAsPdfIcon />
                          </div>
                          <div>
                            <p className="text-center text-sm font-medium">
                              {data.documents.report.label}
                            </p>
                          </div>
                          <div>
                            <p className="text-right">
                              <VisibilityIcon />
                            </p>
                          </div>
                        </div>
                      </ListItemButton>
                      <Divider />
                    </>
                  )}
                </List>
              </div>
              {ticketToken && data.detail.importo_lordo_1 && (
                <PagamentoCard ticket={data} ticketToken={ticketToken} />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
