import { useQuery } from "@tanstack/react-query";
import { publicApiEndpoint } from "../config/config";
import { Faq } from "../models/faq.model";
import { Category } from "../models/Category.model";

const getFaqs = (uid: string) => fetch(publicApiEndpoint + `/assistenza/device/${uid}/faq/list`)
  .then(res => res.json() as Promise<Faq[]>);

export function useGetFaqs({ uid }: { uid?: string }) {
  return useQuery<Faq[]>({
    queryKey: ['faq', uid],
    queryFn: (context) => {
      if (!uid) {
        throw new Error('Faq not found')
      }
      return getFaqs(uid)
        .then(one => {
          if (!one) {
            throw new Error('Faq not found')
          }
          return one;
        })
    }
  })
}

const listCategories = () => fetch(publicApiEndpoint + '/assistenza/categories')
  .then(res => res.json() as Promise<Category[]>);

export function useListCategories() {
  return useQuery<Category[]>({
    queryKey: ['categories'],
    queryFn: listCategories,
    cacheTime: 3000,
  })
}