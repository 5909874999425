import { CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { PagamentoCard } from "../../components/PagamentoCard";
import { PageError } from "../../components/PageError";
import { useGetTicket } from "../../services/ticket.service";
import { FormattedMessage } from "react-intl";

export default function CallbackPagamento({success}: { success: boolean }) {
  const { ticketToken } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetTicket(ticketToken!);

  return (

    <div className="box-form container mx-auto my-10 py-4 px-8">
      {success && (
        <h1 className="text-center"><FormattedMessage
        id="pagamento_effettuato"
        defaultMessage="Pagamento Effettuato"
        description="CallbackPagamento Pagamento Effettuato"
      /></h1>
      )}
      {!success && (
        <h1 className="text-center"><FormattedMessage
        id="pagamento_errato"
        defaultMessage="Errore nel pagamento"
        description="CallbackPagamento Errore nel pagamento"
      /></h1>
      )}
      {isLoading && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {!isLoading && isError && (
        <div className="page-loading">
          <PageError errorMessage="Error" />
        </div>
      )}
      {data && (
      <PagamentoCard ticket={data!} ticketToken= {ticketToken!} />
      )}
    </div>
  );
}
