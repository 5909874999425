import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// console.log('HOST:   ' + window.location.host);
// console.log('HREF:   ' + window.location.href);
if (window.location.host.startsWith("www.clempad")) {
  window.location.href = window.location.href.replace('www.clempad','clempad')
}
if (window.location.host.startsWith("clemstation")) {
  window.location.href = window.location.href.replace('clemstation','clempad')
}
if (window.location.host.startsWith("www.clemstation")) {
  window.location.href = window.location.href.replace('www.clemstation','clempad')
}
if (window.location.host.startsWith("myclembox")) {
  window.location.href = window.location.href.replace('myclembox','clempad')
}
if (window.location.host.startsWith("www.myclembox")) {
  window.location.href = window.location.href.replace('www.myclembox','clempad')
}
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
