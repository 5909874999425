import { useMutation } from "@tanstack/react-query";
import { publicApiEndpoint } from "../config/config";
import { RetailerReqBody, Rivenditore } from "../models/rivenditore.model";
import { fetchJson } from "./http.service";

const getRivenditore = (code: string, captchaToken: string) => fetch(publicApiEndpoint + '/assistenza/fornitore?' + new URLSearchParams({
  code,
}))
  .then(res => res.json() as Promise<Rivenditore>);

type LoginRivenditoreVariables = { code: string, captchaToken: string }
export function useLoginRivenditore() {
  return useMutation<Rivenditore, unknown, LoginRivenditoreVariables>({
    mutationFn: ({ code, captchaToken }) => {
      if (!code) {
        throw new Error('Retailer not found')
      }
      return getRivenditore(code, captchaToken)
        .then(one => {
          if (!one) {
            
            throw new Error('Retailer not found')
          }
          return one;
        })
    }
  })
}

const createRetailer = (dataForm: RetailerReqBody, captchaToken: string) => fetchJson<any>(publicApiEndpoint + `/assistenza/retailer`, {
  method: 'POST',
  body: JSON.stringify({
    ...dataForm,
    captchaToken
  })
});

type CreateRetailerVariables = { dataForm: RetailerReqBody, captchaToken: string }
export function useCreateRetailer() {
  return useMutation<{id: string}, unknown, CreateRetailerVariables>(async ({ dataForm, captchaToken }) => {
    const one = await createRetailer(dataForm, captchaToken);
    if (!one) {
      throw new Error('Create Retailer not work');
    }
    return one;
  })
}
