import HCaptcha from "@hcaptcha/react-hcaptcha";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { PageError } from "../../components/PageError";
import { TicketAssistenzaInviato } from "../../components/TicketAssistenzaInviato";
import { LangContext } from "../../contexts/lang.context";
import { Rivenditore } from "../../models/rivenditore.model";
import {
  CustomerTicketReqBody,
  RetailerTicketReqBody,
} from "../../models/ticket.model";
import { useCateneCommerciali } from "../../services/catenaCommerciale.service";
import { useListDevices } from "../../services/device.service";
import { nazioni, province } from "../../services/nazioniEprovince";
import { useGetSpecifiche } from "../../services/specifiche.service";
import {
  useCreateTicket,
  useGetCampiTicket,
} from "../../services/ticket.service";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function TicketAssistenza() {
  const { uid } = useParams();
  let dataForm: CustomerTicketReqBody | RetailerTicketReqBody;
  const { lang } = useContext(LangContext);
  const { formatMessage } = useIntl();
  const [errorFile, setErrorFile] = useState(false);
  const fileRef = useRef<any>(null);
  const rivenditoreSession = sessionStorage.getItem("rivenditore");
  let rivenditore: Rivenditore | undefined = undefined;
  if (rivenditoreSession !== null) {
    try {
      rivenditore = JSON.parse(rivenditoreSession);
    } catch (error) {}
  }

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  const captchaRef = useRef<HCaptcha>(null);
  const [hCaptchaToken, setHCaptchaToken] = useState<undefined | string>(
    undefined
  );

  const { data: cateneCommerciali, isLoading: isLoadingCatena } =
    useCateneCommerciali({ lang });

  const {
    data: devices,
    error: errorDevice,
    isLoading: isLoadingDevice,
  } = useListDevices();

  const {
    data: specifiche,
    error: errSpec,
    isLoading: isLoadingSpec,
  } = useGetSpecifiche();

  const user = !!rivenditore ? "retailer" : "customer";
  const {
    data: campi,
    error: errCampi,
    isLoading: isLoadingCampi,
  } = useGetCampiTicket({
    lang,
    user,
  });

  // handle generico
  const handleGenericChange =
    (setter: (value: any) => void) =>
    (event: SelectChangeEvent | ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };

  // Variabili select nel form
  const [nazione, setNazione] = useState<string | undefined>(
    rivenditore?.country?.toLocaleUpperCase() || undefined
  );
  const nazioneSelected = useMemo(
    () => nazioni?.find((one) => nazione === one.code),
    [nazione]
  );

  const [provincia, setProvincia] = useState<string | undefined>(
    rivenditore?.province || undefined
  );
  const provinciaSelected = useMemo(
    () => province?.find((one) => provincia === one.code),
    [provincia]
  );

  const [device, setDevice] = useState(uid);
  const handleChangeDevice = (event: SelectChangeEvent) => {
    setDevice(event.target.value as string);
  };
  const [specifica, setSpecifica] = useState("");
  const handleChangeSpecifica = (event: SelectChangeEvent) => {
    setSpecifica(event.target.value as string);
  };
  const [problema, setProblema] = useState("");
  const handleChangeProblema = (event: SelectChangeEvent) => {
    setProblema(event.target.value as string);
  };
  const [catenaComm, setCatenaCommerciale] = useState(
    rivenditore?.store_brand || ""
  );

  // Variabili input nel form
  const [cognome, setCognome] = useState("");
  const handleChangeCognome = (event: ChangeEvent<HTMLInputElement>) => {
    setCognome(event.target.value);
  };
  const [nome, setNome] = useState("");
  const handleChangeNome = (event: ChangeEvent<HTMLInputElement>) => {
    setNome(event.target.value);
  };
  const [nomeNegozio, setNomeNegozio] = useState(rivenditore?.store_name || "");
  const handleChangeNomeNegozio = (event: ChangeEvent<HTMLInputElement>) => {
    setNomeNegozio(event.target.value);
  };
  const [piva, setPIVA] = useState(rivenditore?.vat || "");
  const handleChangePIVA = (event: ChangeEvent<HTMLInputElement>) => {
    setPIVA(event.target.value);
  };
  const [indirizzo1, setIndirizzo1] = useState(rivenditore?.address1 || "");
  const handleChangeIndirizzo1 = (event: ChangeEvent<HTMLInputElement>) => {
    setIndirizzo1(event.target.value);
  };
  const [indirizzo2, setIndirizzo2] = useState(rivenditore?.address2 || "");
  const handleChangeIndirizzo2 = (event: ChangeEvent<HTMLInputElement>) => {
    setIndirizzo2(event.target.value);
  };
  const [indirizzo3, setIndirizzo3] = useState("");
  const handleChangeIndirizzo3 = (event: ChangeEvent<HTMLInputElement>) => {
    setIndirizzo3(event.target.value);
  };
  const [cap, setCap] = useState(rivenditore?.post_code || "");
  const handleChangeCap = (event: ChangeEvent<HTMLInputElement>) => {
    setCap(event.target.value);
  };
  const [telefono, setTelefono] = useState(rivenditore?.phone || "");
  const handleChangeTelefono = (event: ChangeEvent<HTMLInputElement>) => {
    setTelefono(event.target.value);
  };
  const [email, setEmail] = useState(rivenditore?.email || "");
  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const [citta, setCitta] = useState(rivenditore?.city || "");
  const handleChangeCitta = (event: ChangeEvent<HTMLInputElement>) => {
    setCitta(event.target.value);
  };
  const [codiceFiscale, setCodiceFiscale] = useState("");
  const handleChangeCodiceFiscale = (event: ChangeEvent<HTMLInputElement>) => {
    setCodiceFiscale(event.target.value);
  };
  const [meseAquisto, setMeseAquisto] = useState("");
  const handleChangeMeseAquisto = (event: ChangeEvent<HTMLInputElement>) => {
    setMeseAquisto(event.target.value);
  };
  const [annoAquisto, setAnnoAquisto] = useState("");
  const handleChangeAnnoAquisto = (event: ChangeEvent<HTMLInputElement>) => {
    setAnnoAquisto(event.target.value);
  };
  const [referente, setReferente] = useState("");
  const handleChangeReferente = (event: ChangeEvent<HTMLInputElement>) => {
    setReferente(event.target.value);
  };
  const [messaggio, setMessaggio] = useState("");
  const handleChangeMessaggio = (event: ChangeEvent<HTMLInputElement>) => {
    setMessaggio(event.target.value);
  };

  // file
  const [file, setFile] = useState<File | undefined>();
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    // controllo dimensione file
    if (
      e.target.files &&
      e.target.files.length > 0 &&
      e.target.files[0].size < 3670016
    ) {
      setFile(e.target.files[0]);
    } else {
      e.currentTarget.value = "";
      setErrorFile(true);
    }
  };
  useEffect(() => {
    const timeout = setTimeout(() => setErrorFile(false), 3000);
    return () => clearTimeout(timeout);
  }, [errorFile]);
  function deleteFiles() {
    setFile(undefined);
    fileRef.current.value = "";
  }

  //checkbox
  const [informativaFIN1, setInformativaFIN1] = useState<boolean>(false);
  const handleInputChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setInformativaFIN1(event.target.checked);
  };
  const [informativaFIN2, setInformativaFIN2] = useState<boolean>(false);
  const handleInputChangeCheckbox2 = (event: ChangeEvent<HTMLInputElement>) => {
    setInformativaFIN2(event.target.checked);
  };

  const deviceSelected = useMemo(() => {
    return devices?.find((one) => one.uid === device);
  }, [devices, device]);

  // POST registrazione
  const {
    mutateAsync,
    isLoading: isLoadingSend,
    isError: isErrorSend,
    data,
  } = useCreateTicket();

  function createTicket(): void {
    if (!hCaptchaToken) {
      return;
    }
    if (user === "customer") {
      dataForm = {
        message: messaggio,
        spec_zd: specifiche?.find((one) => one.uid === specifica)
          ?.zendesk_specification!,
        issue_zd: problema,
        purch_dd: undefined,
        purch_mm: parseInt(meseAquisto),
        purch_aa: parseInt(annoAquisto),
        informativaFIN1,
        informativaFIN2,
        deviceId: device!,
        user: {
          name: nome,
          surname: cognome,
          address_1: indirizzo1,
          address_2: indirizzo2,
          address_3: indirizzo3,
          phone: telefono,
          email: email,
          personal: codiceFiscale,
          province: provincia,
          city: citta,
          cap: cap,
        },
      };
    } else if (user === "retailer") {
      dataForm = {
        message: messaggio,
        spec_zd: specifica,
        issue_zd: problema,
        purch_dd: undefined,
        purch_mm: parseInt(meseAquisto),
        purch_aa: parseInt(annoAquisto),
        informativaFIN1,
        deviceId: device!,
        referent: referente,
        user: {
          storeName: nomeNegozio,
          storeBrand: catenaComm,
          vatNumber: piva,
          address_1: indirizzo1,
          address_2: indirizzo2,
          address_3: indirizzo3,
          phone: telefono,
          email: email,
          province: provincia,
          city: citta,
          cap: cap,
        },
      };
    } else {
      return;
    }
    mutateAsync({
      captchaToken: hCaptchaToken,
      country: lang,
      dataForm: dataForm,
      file: file,
      user: user,
    }).catch((e) => {
      captchaRef.current?.resetCaptcha();
      console.error(e);
    });
  }

  const isLoadingGeneral =
    isLoadingCampi || isLoadingDevice || isLoadingSpec || isLoadingCatena;
  const errorGeneral = errCampi || errorDevice || errSpec;

  let isFormValid: boolean;
  if (!rivenditore) {
    // Form valid customer
    isFormValid = nazione !== "" && cognome !== "" && nome !== "" && indirizzo1 !== "" && telefono !== "" && cap !== "" && email !== "" && citta !== "" && 
    codiceFiscale !== "" && device !== "" && specifica !== "" && problema !== "" && messaggio !== "";
  } else {
    // Form valid retailer
    isFormValid = nazione !== "" && catenaComm !== "" &&  nomeNegozio !== "" && indirizzo1 !== "" && cap !== "" && piva !== "" && telefono !== "" && 
    email !== "" && citta !== "" && device  !== "" && referente !== "" && specifica !== "" && problema !== "" && messaggio !== "";
  }

  return (
    <>
      {isLoadingGeneral && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {!isLoadingGeneral && !!errorGeneral && (
        <div className="page-loading">
          <PageError errorMessage="Error" />
        </div>
      )}
      {!isLoadingSend && !isErrorSend && data && (
        <TicketAssistenzaInviato
          onClose={handleClose}
          open={open}
          ticket={data}
        />
      )}
      {campi && devices && (
        <div className="contenitore-pagina-ticket-assistenza px-4">
          <div className="box-form container mx-auto my-10 py-4 px-8 bg-[#FFFFFF] shadow-xl">
            <Link to=".." relative="path">
              <Button className="buttons-style" variant="contained">
                <ArrowCircleLeftIcon className="icon-button" />{" "}
                <span className="w-full">
                  <FormattedMessage
                    id="aftersale_backlink"
                    defaultMessage="Torna indietro"
                    description="TicketAssistenza Torna indietro"
                  />
                </span>
              </Button>
            </Link>
            <div className="">
              <p className="text-2xl">
                <FormattedMessage
                  id="retail_logged_contact_title"
                  defaultMessage="Invia un messaggio:"
                  description="TicketAssistenza Invia un messaggio:"
                />
              </p>
              <p className="underline">
                <FormattedMessage
                  id="faq_popup_contact_address_message"
                  defaultMessage="!!! ATTENZIONE!!! L'indirizzo inserito sarà quello utilizzato per l'eventuale ritiro e restituzione del prodotto."
                  description="TicketAssistenza ATTENZIONE"
                />
              </p>
              <p className="text-right">
                <FormattedMessage
                  id="retail_logged_contact_required"
                  defaultMessage="*Campi Obbligatori"
                  description="TicketAssistenza *Campi Obbligatori"
                />
              </p>
              <p className="font-semibold">
                <FormattedMessage
                  id="nazione-forms"
                  defaultMessage="Nazione"
                  description="TicketAssistenza Nazione"
                />
                *
              </p>
              <Autocomplete
                disablePortal
                disabled={!!rivenditore?.country}
                fullWidth
                id="autocomplete-nazioni"
                size="small"
                options={nazioni}
                sx={{ maxWidth: 356 }}
                value={nazioneSelected}
                getOptionLabel={(option) => option.label}
                onChange={(e, value) => setNazione(value?.code)}
                renderInput={(params) => <TextField {...params} />}
              />
              {nazione && rivenditore && (
                <>
                  <p className="font-semibold">
                    <FormattedMessage
                      id="faq_popup_contact_storebrand"
                      defaultMessage="Nome catena commerciale"
                      description="TicketAssistenza Nome catena commerciale"
                    />
                    *
                  </p>
                  <Select
                    error={isErrorSend && catenaComm === ""}
                    disabled={!!rivenditore?.store_brand}
                    fullWidth
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    name="nomeCatenaCommerciale"
                    size="small"
                    MenuProps={MenuProps}
                    value={catenaComm}
                    onChange={handleGenericChange(setCatenaCommerciale)}
                  >
                    {cateneCommerciali?.map((catena) => (
                      <MenuItem value={catena.uid}>{catena.name}</MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </div>
            {nazione && (
              // tutto il resto del form
              <>
                <div className="grid gap-4 grid-cols-2">
                  <div>
                    {!rivenditore && (
                      <>
                        <p className="font-semibold">
                          <FormattedMessage
                            id="faq_popup_contact_surname"
                            defaultMessage="Cognome"
                            description="TicketAssistenza Cognome"
                          />
                          *
                        </p>
                        <TextField
                          error={isErrorSend || cognome === ""}
                          fullWidth
                          label={
                            <FormattedMessage
                              id="faq_popup_contact_surname"
                              defaultMessage="Cognome"
                              description="TicketAssistenza Cognome"
                            />
                          }
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          name="cognome"
                          value={cognome}
                          onChange={handleChangeCognome}
                          inputProps={{ minLength: 1, maxLength: 30 }}
                        />
                      </>
                    )}
                    {rivenditore && (
                      <>
                        <p className="font-semibold">
                          <FormattedMessage
                            id="faq_popup_contact_storename"
                            defaultMessage="Nome negozio"
                            description="TicketAssistenza Nome negozio"
                          />
                          *
                        </p>
                        <TextField
                          error={isErrorSend && nomeNegozio === ""}
                          disabled={!!rivenditore?.store_name}
                          fullWidth
                          label={
                            <FormattedMessage
                              id="faq_popup_contact_storename"
                              defaultMessage="Nome negozio"
                              description="TicketAssistenza Nome negozio"
                            />
                          }
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          name="nomeNegozio"
                          value={nomeNegozio}
                          onChange={handleChangeNomeNegozio}
                          inputProps={{ minLength: 1, maxLength: 30 }}
                        />
                      </>
                    )}
                    <p className="font-semibold">
                      <FormattedMessage
                        id="faq_popup_contact_address"
                        defaultMessage="Indirizzo"
                        description="TicketAssistenza Indirizzo"
                      />
                      *
                    </p>
                    <TextField
                      error={isErrorSend || indirizzo1 === ""}
                      disabled={!!rivenditore?.address1}
                      fullWidth
                      label={
                        <FormattedMessage
                          id="faq_customer_placeholder_address1"
                          defaultMessage="Indirizzo ritiro prodotto"
                          description="TicketAssistenza Indirizzo ritiro prodotto"
                        />
                      }
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      name="indirizzo1"
                      value={indirizzo1}
                      onChange={handleChangeIndirizzo1}
                      inputProps={{ minLength: 1, maxLength: 60 }}
                    />
                    <div className="mt-2">
                      <TextField
                        fullWidth
                        disabled={!!rivenditore?.address2}
                        label={
                          <FormattedMessage
                            id="faq_customer_placeholder_address2"
                            defaultMessage="Indirizzo ritiro prodotto"
                            description="TicketAssistenza Indirizzo ritiro prodotto"
                          />
                        }
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        name="indirizzo2"
                        value={indirizzo2}
                        onChange={handleChangeIndirizzo2}
                      />
                    </div>
                    {campi!.enable_address_3 && (
                      <div className="mt-2">
                        <TextField
                          fullWidth
                          label={
                            <FormattedMessage
                              id="faq_customer_placeholder_address3"
                              defaultMessage="Indirizzo ritiro prodotto"
                              description="TicketAssistenza Indirizzo ritiro prodotto"
                            />
                          }
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          name="indirizzo3"
                          value={indirizzo3}
                          onChange={handleChangeIndirizzo3}
                        />
                      </div>
                    )}
                    <div className="grid gap-4 grid-cols-2">
                      <div>
                        <p className="font-semibold">
                          <FormattedMessage
                            id="faq_popup_contact_cap"
                            defaultMessage="Cap"
                            description="TicketAssistenza Cap"
                          />
                          *
                        </p>
                        <TextField
                          error={isErrorSend || cap === ""}
                          disabled={!!rivenditore?.post_code}
                          fullWidth
                          label={
                            <FormattedMessage
                              id="faq_popup_contact_cap"
                              defaultMessage="Cap"
                              description="TicketAssistenza Cap"
                            />
                          }
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          name="cap"
                          type="number"
                          value={cap}
                          onChange={handleChangeCap}
                          inputProps={{ minLength: 1, maxLength: 12 }}
                        />
                      </div>
                      <div>
                        {campi!.enable_province && (
                          <>
                            <p className="font-semibold">
                              <FormattedMessage
                                id="faq_popup_contact_province"
                                defaultMessage="Provincia"
                                description="TicketAssistenza Provincia"
                              />
                            </p>
                            <Autocomplete
                              disablePortal
                              disabled={!!rivenditore?.province}
                              fullWidth
                              id="combo-box-demo"
                              size="small"
                              options={province}
                              value={provinciaSelected}
                              getOptionLabel={(option) => option.label}
                              onChange={(e, value) => setProvincia(value?.code)}
                              renderInput={(params) => (
                                <TextField error={!provincia} {...params} />
                              )}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    {!rivenditore && (
                      <>
                        <p className="font-semibold">
                          <FormattedMessage
                            id="faq_popup_contact_name"
                            defaultMessage="Nome"
                            description="TicketAssistenza Nome"
                          />
                          *
                        </p>
                        <TextField
                          error={isErrorSend || nome === ""}
                          fullWidth
                          label={
                            <FormattedMessage
                              id="faq_popup_contact_name"
                              defaultMessage="Nome"
                              description="TicketAssistenza Nome"
                            />
                          }
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          name="nome"
                          value={nome}
                          onChange={handleChangeNome}
                          inputProps={{ minLength: 1, maxLength: 30 }}
                        />
                      </>
                    )}
                    {rivenditore && (
                      <>
                        <p className="font-semibold">
                          <FormattedMessage
                            id="faq_popup_contact_vatnumber"
                            defaultMessage="Partita IVA"
                            description="TicketAssistenza Partita IVA"
                          />
                          *
                        </p>
                        <TextField
                          error={isErrorSend || piva === ""}
                          disabled={!!rivenditore?.vat}
                          fullWidth
                          label={
                            <FormattedMessage
                              id="faq_popup_contact_vatnumber"
                              defaultMessage="Partita IVA"
                              description="TicketAssistenza Partita IVA"
                            />
                          }
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          name="piva"
                          value={piva}
                          onChange={handleChangePIVA}
                          inputProps={{ minLength: 1, maxLength: 16 }}
                        />
                      </>
                    )}
                    <p className="font-semibold">
                      <FormattedMessage
                        id="faq_popup_contact_phone"
                        defaultMessage="Telefono"
                        description="TicketAssistenza Telefono"
                      />
                      *
                    </p>
                    <TextField
                      error={isErrorSend || telefono === ""}
                      disabled={!!rivenditore?.phone}
                      fullWidth
                      label={
                        <FormattedMessage
                          id="faq_popup_contact_phone"
                          defaultMessage="Telefono"
                          description="TicketAssistenza Telefono"
                        />
                      }
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      name="telefono"
                      value={telefono}
                      onChange={handleChangeTelefono}
                      inputProps={{ maxLength: 15 }}
                    />
                    <p className="font-semibold">
                      <FormattedMessage
                        id="faq_popup_contact_email"
                        defaultMessage="Email"
                        description="TicketAssistenza Email"
                      />
                      *
                    </p>
                    <TextField
                      error={
                        isErrorSend || email === "" || email.search("@") === -1
                      }
                      disabled={!!rivenditore?.email}
                      fullWidth
                      label={
                        <FormattedMessage
                          id="faq_popup_contact_email"
                          defaultMessage="Email"
                          description="TicketAssistenza Email"
                        />
                      }
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      name="email"
                      value={email}
                      type="email"
                      onChange={handleChangeEmail}
                    />
                    <p className="font-semibold">
                      <FormattedMessage
                        id="retail_logged_contact_city"
                        defaultMessage="Città"
                        description="TicketAssistenza Città"
                      />
                      *
                    </p>
                    <TextField
                      error={isErrorSend || citta === ""}
                      disabled={!!rivenditore?.city}
                      fullWidth
                      label={
                        <FormattedMessage
                          id="retail_logged_contact_city"
                          defaultMessage="Città"
                          description="TicketAssistenza Città"
                        />
                      }
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      name="citta"
                      value={citta}
                      onChange={handleChangeCitta}
                      inputProps={{ minLength: 1, maxLength: 30 }}
                    />
                  </div>
                </div>
                {campi!.enable_personal_number && (
                  <>
                    <p className="font-semibold">
                      <FormattedMessage
                        id="faq_popup_contact_personal"
                        defaultMessage="Codice Fiscale"
                        description="TicketAssistenza Codice Fiscale"
                      />
                      *
                    </p>
                    <TextField
                      error={
                        isErrorSend ||
                        codiceFiscale === "" ||
                        codiceFiscale.length !== 16
                      }
                      fullWidth
                      label={
                        <FormattedMessage
                          id="faq_popup_contact_personal"
                          defaultMessage="Codice Fiscale"
                          description="TicketAssistenza Codice Fiscale"
                        />
                      }
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      value={codiceFiscale}
                      onChange={handleChangeCodiceFiscale}
                      inputProps={{ minLength: 11, maxLength: 16 }}
                    />
                  </>
                )}
                <p className="font-semibold">
                  <FormattedMessage
                    id="faq_popup_contact_model"
                    defaultMessage="Selezione Modello"
                    description="TicketAssistenza Selezione Modello"
                  />
                  *
                </p>
                <FormControl fullWidth size="small">
                  <InputLabel id="select-selezione-modello-label">
                    <FormattedMessage
                      id="faq_popup_contact_model"
                      defaultMessage="Selezione Modello"
                      description="TicketAssistenza Selezione Modello"
                    />
                  </InputLabel>
                  <Select
                    labelId="select-selezione-modello-label"
                    id="demo-select-small"
                    disabled={true}
                    MenuProps={MenuProps}
                    value={device}
                    label={
                      <FormattedMessage
                        id="faq_popup_contact_model"
                        defaultMessage="Selezione Modello"
                        description="TicketAssistenza Selezione Modello"
                      />
                    }
                    onChange={handleChangeDevice}
                  >
                    {devices?.map((device) => (
                      <MenuItem value={device.uid}>{device.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="grid gap-4 grid-cols-2">
                  <div>
                    <p className="font-semibold">
                      <FormattedMessage
                        id="faq_popup_purch_mm"
                        defaultMessage="Mese acquisto"
                        description="TicketAssistenza Mese acquisto"
                      />
                    </p>
                    <TextField
                      fullWidth
                      label={
                        <FormattedMessage
                          id="faq_popup_purch_mm"
                          defaultMessage="Mese acquisto"
                          description="TicketAssistenza Mese acquisto"
                        />
                      }
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      type="number"
                      value={meseAquisto}
                      onChange={handleChangeMeseAquisto}
                      inputProps={{ min: 1, max: 12, maxLength: 2 }}
                    />
                  </div>
                  <div>
                    <p className="font-semibold">
                      <FormattedMessage
                        id="faq_popup_purch_aa"
                        defaultMessage="Anno acquisto"
                        description="TicketAssistenza Anno acquisto"
                      />
                    </p>
                    <TextField
                      fullWidth
                      label={
                        <FormattedMessage
                          id="faq_popup_purch_aa"
                          defaultMessage="Anno acquisto"
                          description="TicketAssistenza Anno acquisto"
                        />
                      }
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      type="number"
                      value={annoAquisto}
                      onChange={handleChangeAnnoAquisto}
                      inputProps={{
                        min: 2010,
                        max: new Date().getFullYear(),
                        maxLength: 4,
                      }}
                    />
                  </div>
                </div>
                {rivenditore && (
                  <>
                    <p className="font-semibold">
                      <FormattedMessage
                        id="faq_popup_contact_reference"
                        defaultMessage="Referente postvendita (oppure rif. Cliente)"
                        description="TicketAssistenza Referente postvendita"
                      />
                      *
                    </p>
                    <TextField
                      error={isErrorSend || referente === ""}
                      fullWidth
                      label={
                        <FormattedMessage
                          id="faq_popup_contact_reference"
                          defaultMessage="Referente postvendita (oppure rif. Cliente)"
                          description="TicketAssistenza Referente postvendita"
                        />
                      }
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      name="referente"
                      value={referente}
                      onChange={handleChangeReferente}
                    />
                  </>
                )}
                <p className="font-semibold">
                  <FormattedMessage
                    id="faq_popup_contact_details"
                    defaultMessage="Specifiche"
                    description="TicketAssistenza Specifiche"
                  />
                  *
                </p>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-select-small-label">
                    <FormattedMessage
                      id="customer_form_select_specifics_option"
                      defaultMessage="Selezione Specifica"
                      description="TicketAssistenza Selezione Specifica"
                    />
                  </InputLabel>
                  <Select
                    error={isErrorSend || specifica === ""}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    MenuProps={MenuProps}
                    value={specifica}
                    label={
                      <FormattedMessage
                        id="customer_form_select_specifics_option"
                        defaultMessage="Selezione Specifica"
                        description="TicketAssistenza Selezione Specifica"
                      />
                    }
                    onChange={handleChangeSpecifica}
                  >
                    {specifiche
                      ?.sort((a, b) => (a.order || 0) - (b.order || 0))
                      .map((spec) => (
                        <MenuItem value={spec.uid}>
                          {spec.translations[lang]}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <div className="grid gap-4 grid-cols-2">
                  <div>
                    <p className="font-semibold">
                      <FormattedMessage
                        id="faq_popup_contact_found-issue"
                        defaultMessage="Problema riscontrato"
                        description="TicketAssistenza Problema riscontrato"
                      />
                      *
                    </p>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-select-small-label">
                        <FormattedMessage
                          id="faq_popup_contact_found-issue"
                          defaultMessage="Problema riscontrato"
                          description="TicketAssistenza Problema riscontrato"
                        />
                      </InputLabel>
                      <Select
                        error={isErrorSend || problema === ""}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        MenuProps={MenuProps}
                        value={problema}
                        label={
                          <FormattedMessage
                            id="faq_popup_contact_found-issue"
                            defaultMessage="Problema riscontrato"
                            description="TicketAssistenza Problema riscontrato"
                          />
                        }
                        onChange={handleChangeProblema}
                      >
                        {specifiche
                          ?.find((spec) => spec.uid === specifica)
                          ?.issues.filter(
                            (issu) =>
                              issu.macroModel === deviceSelected?.macroModel
                          )
                          .sort((a, b) => (a.order || 0) - (b.order || 0))
                          ?.map((issue) => (
                            <MenuItem value={issue.zendesk_issue}>
                              {issue.translations[lang]}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <p className="font-semibold">
                      <FormattedMessage
                        id="faq_popup_contact_image"
                        defaultMessage="Allega file"
                        description="TicketAssistenza Allega file"
                      />
                    </p>
                    <Button
                      className="buttons-style"
                      fullWidth
                      variant="contained"
                      sx={{ maxWidth: '206px' }}
                      component="label"
                    >
                      Upload File
                      <input
                        type="file"
                        accept="image/*,application/pdf,application/zip"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </Button>
                    <Button ref={fileRef} onClick={deleteFiles}>
                      <ClearIcon sx={{ color: "#757575" }} />
                    </Button>
                    <div>{file && `${file.name} - ${file.type}`}</div>
                    {errorFile && (
                      <p className="text-red-600">
                        <FormattedMessage
                          id="errore-dimensione-file"
                          defaultMessage="Errore dimensione file"
                          description="TicketAssistenza messaggio Errore dimensione file"
                        />{" "}
                        (max 3 MB)
                      </p>
                    )}
                  </div>
                </div>
                <p className="font-semibold">
                  <FormattedMessage
                    id="faq_popup_contact_message"
                    defaultMessage="Messaggio"
                    description="TicketAssistenza Messaggio"
                  />
                  *
                </p>
                <TextField
                  error={isErrorSend || messaggio === ""}
                  fullWidth
                  multiline
                  label={
                    <FormattedMessage
                      id="faq_popup_contact_message"
                      defaultMessage="Messaggio"
                      description="TicketAssistenza Messaggio"
                    />
                  }
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  rows={4}
                  value={messaggio}
                  onChange={handleChangeMessaggio}
                />
                <FormGroup>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        name="informativaFIN1"
                        value={informativaFIN1}
                        onChange={handleInputChangeCheckbox}
                      />
                    }
                    label={
                      <p
                        className="font-semibold"
                        dangerouslySetInnerHTML={{
                          __html: formatMessage({
                            id: "faq_popup_contact_privacy",
                            defaultMessage:
                              "Letta l’informativa, con riferimento al trattamento dei dati a fini di gestione delle richieste da parte dei clienti - Finalità 1 dell’informativa\n<a href='http://clempad.clementoni.com/pdf/privacy_policy_it_IT.pdf' target='_blank'>informativa</a>",
                            description: "TicketAssistenza Letta l’informativa",
                          }),
                        }}
                      ></p>
                    }
                  />
                </FormGroup>
                <span className="text-red-600 mt-0" hidden={informativaFIN1}>
                  <FormattedMessage
                    id="form_required_field"
                    defaultMessage="Il campo è obbligatorio"
                    description="TicketAssistenza Il campo è obbligatorio"
                  />
                </span>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="informativaFIN2"
                        value={informativaFIN2}
                        onChange={handleInputChangeCheckbox2}
                      />
                    }
                    label={
                      <p
                        className="font-semibold"
                        dangerouslySetInnerHTML={{
                          __html: formatMessage({
                            id: "faq_popup_contact_privacy_2",
                            defaultMessage:
                              "Letta l’informativa, con riferimento al trattamento dei dati a fini statistici e di profilazione, per lo sviluppo, la manutenzione ed il miglioramento dei prodotti e dei nostri servizi, oltre che per l’invio di informazioni, comunicazioni ed offerte commerciali - Finalità 2 dell’informativa\n<a href='http://clempad.clementoni.com/pdf/privacy_policy_it_IT.pdf' target='_blank'>informativa</a>",
                            description:
                              "TicketAssistenza Letta l’informativa 2",
                          }),
                        }}
                      ></p>
                    }
                  />
                </FormGroup>
                <Button
                  disabled={!hCaptchaToken || !informativaFIN1 || !isFormValid || isLoadingSend}
                  sx={{
                    "&.Mui-disabled": {
                      backgroundColor: "#b3b3b3 !important",
                      color: "white",
                    },
                  }}
                  className="buttons-style"
                  variant="contained"
                  onClick={() => createTicket()}
                >
                  {isLoadingSend && <CircularProgress size={24} />}
                  {!isLoadingSend && (
                    <FormattedMessage
                      id="aftersale_send_button"
                      defaultMessage="Invia"
                      description="TicketAssistenza Invia"
                    />
                  )}
                </Button>
                {isErrorSend && (
                  <p className="text-red-600">
                    <FormattedMessage
                      id="errore-invio"
                      defaultMessage="Errore invio"
                      description="TicketAssistenza messaggio errore invio"
                    />
                  </p>
                )}
                <div className="mt-2">
                  <HCaptcha
                    ref={captchaRef}
                    sitekey={process.env.REACT_APP_hCaptchaKey as string}
                    onVerify={setHCaptchaToken}
                    onExpire={() => setHCaptchaToken(undefined)}
                  ></HCaptcha>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
