import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

export function RegistrazioneInviata() {
  return (
    <div className="page-loading">
      <div className="flex flex-col items-center my-8">
        <div className="box-pagamento flex flex-col shadow-lg my-4">
          <p className="text-2xl font-semibold mb-4">
            <FormattedMessage
              id="formretailer_register_title"
              defaultMessage="Registrazione rivenditore"
              description="RegistrazioneInviata Registrazione rivenditore"
            />
          </p>
          <p className="mb-4">
            <FormattedMessage
              id="formretailer_register_description"
              defaultMessage="!!! ATTENZIONE !!! L'indirizzo sarà utilizzato per il ritiro e la restituzione del prodotto mentre l'email verrà utilizzata per eventuali comunicazioni."
              description="RegistrazioneInviata ATTENZIONE"
            />
          </p>
          <p className="mb-4">
            <FormattedMessage
              id="register_retailer_message1"
              defaultMessage="La richiesta di registrazione verrà presa in carico da un nostro agente. Validato l'account riceverà conferma con le procedure Clementoni."
              description="RegistrazioneInviata La richiesta di registrazione"
            />
          </p>
          <Link to="../.." relative="path">
            <Button className="buttons-style" variant="contained">
              <ArrowCircleLeftIcon className="icon-button" />{" "}
              <span className="w-full">
                <FormattedMessage
                  id="aftersale_backlink"
                  defaultMessage="Torna indietro"
                  description="RegistrazioneInviata Torna indietro"
                />
              </span>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
