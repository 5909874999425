import SearchTicket from "../../components/SearchTicket";
import SelectDevice from "../../components/SelectDevice";

export default function Devices() {
  

  return (
    <>
      <SelectDevice />
      <SearchTicket />
    </>
  );
}
