import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import { MouseEvent, useContext, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { Link, useNavigate } from "react-router-dom";
import DialogRivenditori from "../../components/DialogRivenditori";
import { LangContext } from "../../contexts/lang.context";
import { RivenditoreContext } from "../../contexts/rivenditore.context";
import { Rivenditore } from "../../models/rivenditore.model";
import logo from "./Clementoni logo - colori - positivo.png";
import { FormattedMessage } from "react-intl";
import { Avatar, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";

export default function Header() {
  const navigate = useNavigate();
  const { langs } = useContext(LangContext);
  const { removeRivenditore } = useContext(RivenditoreContext);
  const [select, setSelect] = useState("IT");
  const onSelect = (country: any) => setSelect(country);
  const rivenditoreSession = sessionStorage.getItem("rivenditore");
  let rivenditore: Rivenditore | undefined = undefined;
  if (rivenditoreSession !== null) {
    try {
      rivenditore = JSON.parse(rivenditoreSession);
    } catch (error) {}
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function logout(): void {
    removeRivenditore();
    navigate("/");
  }

  return (
    <div className="bg-[#FFFFFF] w-full shadow-lg border-b-8 border-blue-clem-500">
      <div className="container mx-auto py-4 px-4">
        <div className="flex justify-between items-center">
            <a href="/">
              <img src={logo} alt="Clementoni" className="logo-style"></img>
            </a>
          <div className="">
            {Object.keys(langs).length > 1 && (
              <ReactFlagsSelect
                selected={select}
                onSelect={onSelect}
                countries={["IT", "FR"]}
                showSelectedLabel={false}
                // selectedSize={10}
                showOptionLabel={false}
                // optionsSize={10}
                // placeholder={placeholder}
                // searchable={searchable}
                // searchPlaceholder={searchPlaceholder}
                // alignOptionsToRight={true}
                // fullWidth={fullWidth}
                // disabled={disabled}
              />
            )}
          </div>
          {rivenditore === undefined && (
            <div className="login-rivenditori">
              <DialogRivenditori />
            </div>
          )}
          {rivenditore && (
            <>
              <Tooltip title={rivenditore.email}>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    sx={{ width: 32, height: 32, backgroundColor: "#1D4EBB" }}
                  ></Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <Button
                    variant="contained"
                    onClick={logout}
                    className="buttons-style"
                  >
                    <LogoutIcon className="icon-button" />
                    <span className="w-full">Logout</span>
                  </Button>
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
