import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useParams } from "react-router-dom";
import { LazyImage } from "../../components/LazyImage";
import { PageError } from "../../components/PageError";
import { TableManuals } from "../../components/TableManual";
import { useGetDevice } from "../../services/device.service";
import { useGetManuals } from "../../services/manual.service";
import { FormattedMessage } from "react-intl";

export default function Manuali() {
  const { uid } = useParams();
  const { data: manuals, error, isLoading } = useGetManuals({ uid });
  const {
    data: device,
    error: errDevice,
    isLoading: isLoadingDevice,
  } = useGetDevice({ uid });

  return (
    <>
      {(isLoading || isLoadingDevice) && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {!isLoading && !isLoadingDevice && !!error && !!errDevice && (
        <div className="page-loading">
          <PageError errorMessage="Error" />
        </div>
      )}
      {manuals && device && (
        <>
          <div className="bg-[#FFFFFF] shadow-lg min-height-faq-manuals">
            <div className="container mx-auto py-4 px-4">
              <Link to=".." relative="path">
                <Button className="buttons-style" variant="contained">
                  <ArrowCircleLeftIcon className="icon-button" />{" "}
                  <span className="w-full"><FormattedMessage
                id="565db3d33aa5647c0927f0af"
                defaultMessage="Torna indietro"
                description="Manuali Torna indietro"
              /></span>
                </Button>
              </Link>
              <div className="grid gap-4 grid-cols-3 grid-rows-1 mt-4">
                <div className="flex items-center">
                  <p className="text-2xl">{device.title}</p>
                </div>
                <div>
                  <LazyImage
                    src={device.assets?.find((one) => one.type === 'device')?.path}
                    alt="Immagine dispositivo"
                  />
                </div>
                <div>
                  <p>
                    <LazyImage
                      src={device.assets?.find((one) => one.type === 'pack')?.path}
                      alt="Immagine packaging"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="contenitore-pagina-faq-manuals container mx-auto my-8 px-4">
            <TableManuals manuals={manuals} />
          </div>
        </>
      )}
    </>
  );
}
