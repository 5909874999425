import { ResGetTicket } from "../models/formSearchTicket.model";
import { Button } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import { FormattedMessage } from "react-intl";
import { useInizializePayment } from "../services/ticket.service";
import { useContext } from "react";
import { LangContext } from "../contexts/lang.context";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export function PagamentoCard({
  ticket,
  ticketToken,
}: {
  ticket: ResGetTicket;
  ticketToken: string;
}) {
  const { lang } = useContext(LangContext);
  const { mutateAsync } = useInizializePayment();
  const initializePayment = () => {
    mutateAsync({ ticketToken }).then((res) => {
      window.location.href = res.url;
    });
  };
  // console.log(ticket.detail);

  const id_transazione = ticket.detail.id_transazione;
  return (
    <div className="flex flex-col items-center my-8">
      {id_transazione && (
        <>
        <p className="text-2xl font-semibold">
          Riparazione {ticket.detail.Code_of_product}
        </p>
        <div className="box-pagamento flex flex-col items-center shadow-lg my-4">
          <p className="text-2xl font-semibold mb-4">Ticket {ticket.detail.Clementoni_request_ID}</p>
          <p className="text-xl font-semibold mb-4">
            {(ticket.detail.importo_lordo_1 || 0) +
              (ticket.detail.importo_lordo_2 || 0)}{" "}
            €
          </p>
          <p className="text-center my-2">
            Pagamento effettuato con successo.
          </p>
          <CheckCircleOutlineIcon sx={{ color: '#32CD32', fontSize: 40 }} />
        </div>
        <p className="text-center mt-2">
          Il costo della riparazione è da intendersi iva inclusa; tale costo
          include anche le spese di spedizione del prodotto.
        </p>
      </>
      )}
      {!id_transazione && (
        <>
          <p className="text-2xl font-semibold">
            Riparazione {ticket.detail.Code_of_product}
          </p>
          <div className="box-pagamento flex flex-col items-center shadow-lg my-4">
            <p className="text-2xl font-semibold mb-4">Ticket {ticket.detail.Clementoni_request_ID}</p>
            <p className="text-xl font-semibold mb-4">
              {(ticket.detail.importo_lordo_1 || 0) +
                (ticket.detail.importo_lordo_2 || 0)}{" "}
              €
            </p>
            <p className="text-center">
              Setefy permette di pagare in tutta sicurezza il seguente importo
              tramite Carta di credito o Carta prepagata (Postepay, Visa
              Electron, Mastercard, ecc).
            </p>
          </div>
          <Button
            className="buttons-style margin-bottom-jef"
            variant="contained"
            onClick={initializePayment}
          >
            <PaymentIcon className="icon-button" />
            <span className="w-full">
              <FormattedMessage
                id="pagamento-paga-adesso"
                defaultMessage="Paga Adesso"
                description="PagamentoCard Paga Adesso"
              />
            </span>
          </Button>
          <p className="text-center">
            Il costo della riparazione è da intendersi iva inclusa; tale costo
            include anche le spese di spedizione del prodotto.
          </p>
        </>
      )}
    </div>
  );
}
