import { useQuery } from "@tanstack/react-query";
import { PropsWithChildren, createContext, useMemo, useState } from "react";

const savedLang = window.localStorage.getItem("lang");
const browserLang = navigator?.language?.split("-").at(0) || undefined;
const browserLangs =
  navigator?.languages.map((lang) => lang.split("-").at(0)) || [];

export type PossibleLangs = "it";
export const defaultLang = (savedLang || browserLang || browserLangs[0] || "it") as PossibleLangs;


export type LangsMap = Record<string, { title: string; flag: string }>;
export const LangContext = createContext<{
  lang: PossibleLangs;
  defaultLang: string;
  messages?: Record<string, string>;
  setLang?: (lang: PossibleLangs) => void;
  langs: LangsMap;
}>({
  lang: defaultLang,
  defaultLang: defaultLang,
  messages: undefined,
  setLang: undefined,
  langs: {},
});

export function LangProvider({
  language,
  children,
}: PropsWithChildren<{
  language?: PossibleLangs;
}>) {
  const [lang, setLang] = useState<PossibleLangs>(language || defaultLang);
  const [lastLang, setLastLang] = useState(lang);
  const { data: langs } = useQuery<LangsMap>({
    queryKey: ["langs"],
    queryFn: () => fetch("/langs.json").then((res) => res.json()),
    initialData: {
      it: {
        title: "Italiano",
        flag: "",
      },
    },
  });
  const { data, isLoading } = useQuery<any>({
    queryKey: ["langs", lang],
    queryFn: (context) => {
      return fetch("" + context.queryKey[1]);
    },
  });
  const value = useMemo(() => {
    sessionStorage.setItem("lang", lang);
    if (isLoading) {
      return {
        lang: lastLang,
        messages: data,
        defaultLang: "it",
        setLang,
        langs,
      };
    }
    if (!(lang in langs)) {
      setLang(lastLang);
      return {
        lang: lastLang,
        messages: data,
        defaultLang: "it",
        setLang,
        langs,
      };
    } else {
      setLastLang(lang);
    }
    return {
      lang,
      messages: data,
      defaultLang: "it",
      setLang,
      langs,
    };
  }, [lang, data, isLoading, langs, lastLang]);
  return <LangContext.Provider value={value}>{children}</LangContext.Provider>;
}
