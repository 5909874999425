import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";

export default function LinearProgressWithLabel({
  percentuale,
  stato,
  colore,
  delay = 700,
}: {
  percentuale: number;
  stato: string;
  colore: string;
  delay?: number;
}) {
  const [differedPerc, setDifferedPerc] = useState<undefined | number>(0);
  useEffect(() => {
    setDifferedPerc(0);
    const timeout = setTimeout(() => {
      setDifferedPerc(percentuale);
    }, delay);
    return () => clearTimeout(timeout);
  }, [percentuale, delay]);
  return (
    <LinearProgress
      className="ticket-progress"
      variant={differedPerc !== undefined ? "determinate" : "indeterminate"}
      value={differedPerc}
      content="cheneso"
      title={stato}
      color="inherit"
      sx={{
        color: colore,
        height: "20px",
      }}
    />
  );
}
