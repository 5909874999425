import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useContext } from "react";
import { IntlProvider } from "react-intl";
import {
  Navigate,
  NavigateProps,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useParams,
} from "react-router-dom";
import { LangContext, LangProvider } from "./contexts/lang.context";
import { RivenditoreProvider } from "./contexts/rivenditore.context";
import CallbackPagamento from "./pages/CallbackPagamento/CallbackPagamento";
import Devices from "./pages/Devices/Devices";
import FAQ from "./pages/FAQ/FAQ";
import Footer from "./pages/Footer/Footer";
import Header from "./pages/Header/Header";
import Manuali from "./pages/Manuali/Manuali";
import PagamentoTicket from "./pages/PagamentoTicket/PagamentoTicket";
import PreviewPagamentoTicket from "./pages/PreviewPagamentoTicket/PreviewPagamentoTicket";
import Registrazione from "./pages/Registrazione/Registrazione";
import { RegistrazioneInviata } from "./pages/RegistrazioneInviata/RegistrazioneInviata";
import TicketAssistenza from "./pages/Ticket-Assistenza/TicketAssistenza";
import Ticket from "./pages/Ticket/Ticket";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const DATE_FORMAT = "dd/MM/yyyy";

const theme = createTheme({
  typography: {
    fontFamily: "Nunito, sans-serif",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <LangProvider>
          <RivenditoreProvider>
            <InnerApp></InnerApp>
          </RivenditoreProvider>
        </LangProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

function InnerApp() {
  const { lang, messages } = useContext(LangContext);
  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Devices />,
        },
        {
          path: "/registrazione",
          element: <Registrazione />,
        },
        {
          path: "/registrazione/inviata",
          element: <RegistrazioneInviata />,
        },
        {
          path: "/pagamento/:ticketId",
          element: <PreviewPagamentoTicket />,
        },
        {
          path: "/:lang/payment/:ticketId",
          element: <NavigatePayment />,
        },
        {
          path: "/ticket/:ticketToken",
          element: <Ticket />,
        },
        {
          path: "/ticket/:ticketToken/pagamento",
          element: <PagamentoTicket />,
        },
        {
          path: "/ticket/:ticketToken/pagamento/success",
          element: <CallbackPagamento success={true} />,
        },
        {
          path: "/ticket/:ticketToken/pagamento/error",
          element: <CallbackPagamento success={false} />,
        },
        {
          path: "/dispositivi",
          element: <Devices />,
        },
        {
          path: "/dispositivi/:tipologia",
          element: <Devices />,
        },
        {
          path: "/dispositivi/:tipologia/:uid",
          element: <Devices />,
        },
        {
          path: "/dispositivi/:tipologia/:uid/faq",
          element: <FAQ />,
        },
        {
          path: "/dispositivi/:tipologia/:uid/manuali",
          element: <Manuali />,
        },
        {
          path: "/dispositivi/:tipologia/:uid/ticket-assistenza",
          element: <TicketAssistenza />,
        },
        {
          path: "*",
          element: <PageNotFound />
        }
      ],
    },
  ]);

  return (
    <IntlProvider messages={messages} locale={lang} defaultLocale="it">
      <RouterProvider router={router} />
    </IntlProvider>
  );
}

function NavigatePayment() {
  const { ticketId } = useParams();
  return <Navigate to={`/pagamento/${ticketId}`}></Navigate>;
}

export default App;
