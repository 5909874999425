import { useQuery } from "@tanstack/react-query";
import { publicApiEndpoint } from "../config/config";
import { Device } from "../models/device.model";

const listDevices = () => fetch(publicApiEndpoint + '/assistenza/device/list')
  .then(res => res.json() as Promise<Omit<Device, 'macroModelSlug'>[]>)
  .then(res => res.map(one => ({
    ...one,
    macroModelSlug: one.macroModel.toLowerCase().replaceAll(/[^a-z0-9-]+/gm, '-')
  })).sort((a, b) => a.order - b.order)); // ordinato dal piu piccolo al piu grande

export function useListDevices() {
  return useQuery<Device[]>({
    queryKey: ['devices'],
    queryFn: listDevices,
    cacheTime: 3000,
  })
}

export function useGetDevice({ uid }: { uid: string | undefined }) {
  if (uid === undefined) {
    throw new Error('Device not found')
  }
  return useQuery<Device>({
    queryKey: ['devices', uid],
    queryFn: () =>
      listDevices()
        .then(arr => arr.find((one) => one.uid === uid))
        .then(one => {
          if (!one) {
            throw new Error('Device not found')
          }
          return one;
        })
  })
}
