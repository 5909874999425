import { useQuery } from "@tanstack/react-query";
import { publicApiEndpoint } from "../config/config";
import { CateneCommerciali } from "../models/cateneCommerciali.model";

const getCateneCommerciali = (lang: string) => fetch(publicApiEndpoint + '/assistenza/store-brand/' + lang)
  .then(res => res.json() as Promise<CateneCommerciali[]>);

export function useCateneCommerciali({lang}: {lang: string}) {
  return useQuery<CateneCommerciali[]>({
    queryKey: ['catene-commerciali', lang],
    queryFn: (context) => {
      return getCateneCommerciali(lang)
        .then(one => {
          if (!one) {
            throw new Error('Catene Commerciali not found')
          }
          return one.sort((a, b) => a.name.localeCompare(b.name));
        })
    }
  })
}

export function useGetCatenaCommerciale({lang, uid}: {lang: string, uid: string}) {
  return useQuery<CateneCommerciali>({
    queryKey: ['catena-commerciale', lang, uid],
    queryFn: (context) => {
      return getCateneCommerciali(lang)
        .then(one => {
          if (!one) {
            throw new Error('Catene Commerciali not found')
          }
          const catena = one.find((one) => one.uid === uid);
          if (!catena) throw new Error('Catena Commerciale not found')
          return catena;
        })
    }
  })
}
