import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useContext, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { useLoginRivenditore } from "../services/rivenditore.service";
import { RivenditoreContext } from "../contexts/rivenditore.context";
import HCaptcha from "@hcaptcha/react-hcaptcha";

export default function DialogRivenditori() {
  const navigate = useNavigate();

  const { setRivenditore, removeRivenditore } = useContext(RivenditoreContext);

  const [touched, setTouched] = useState(false);
  const captchaRef = useRef<HCaptcha>(null);
  const [hCaptchaToken, setHCaptchaToken] = useState<undefined | string>(
    undefined
  );

  const [open, setOpen] = useState(false);
  const { mutateAsync, isError } = useLoginRivenditore();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [codice, setCodice] = useState("");
  const handleChangeCodice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    setCodice(event.target.value);
  };

  // Funzione per quando accedi
  function loginRivenditore(codice: string): void {
    if (!hCaptchaToken) {
      return;
    }
    mutateAsync({ code: codice, captchaToken: hCaptchaToken! })
      .then((data) => {
        if (data?.message === "NotFound!") {
          throw new Error();
        } else {
          setRivenditore(data);
          navigate("");
        }
      })
      .catch((error) => {
        removeRivenditore();
        captchaRef.current?.resetCaptcha();
        alert("Error");
        console.error(error);
      });
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        <FormattedMessage
          id="rivenditore-accedi-registrati"
          defaultMessage="Sei un rivenditore? Accedi o registrati"
          description="Header Accedi o registrati rivendore"
        />
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <FormattedMessage
            id="index_retailerlogin"
            defaultMessage="Login Rivenditore"
            description="Header Login Rivenditore"
          />
        </DialogTitle>
        <DialogContent>
          <div className="mb-2">
            <DialogContentText>
              <FormattedMessage
                id="field_retailercode"
                defaultMessage="Inserire il codice per accedere all'area riservata"
                description="Header Inserire il codice per accedere all'area riservata"
              />
              .
            </DialogContentText>
          </div>
          <span className="mb-2">
            <TextField
              error={isError}
              fullWidth
              variant="outlined"
              label="Codice"
              size="small"
              type="text"
              name="codice"
              value={codice}
              onTouchStart={() => setTouched(true)}
              onChange={handleChangeCodice}
            />
          </span>
          {touched && (
            <div className="mt-1">
              <HCaptcha
                ref={captchaRef}
                sitekey={process.env.REACT_APP_hCaptchaKey as string}
                onVerify={setHCaptchaToken}
                onExpire={() => setHCaptchaToken(undefined)}
              ></HCaptcha>
            </div>
          )}
          <p className="mt-1">
            <FormattedMessage
              id="rivenditore-oppure"
              defaultMessage="oppure"
              description="Header oppure"
            />{" "}
            <Link to="/registrazione">
              <Button onClick={handleClose}>
                <FormattedMessage
                  id="btn_register_retailer"
                  defaultMessage="Registrati"
                  description="Header Registrati"
                />
              </Button>
            </Link>
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <FormattedMessage
              id="rivenditore-annulla"
              defaultMessage="Annulla"
              description="Header Annulla"
            />
          </Button>
          <Button
            disabled={!hCaptchaToken && codice.length > 0}
            sx={{
              "&.Mui-disabled": {
                backgroundColor: "#b3b3b3 !important",
                color: "white",
              },
            }}
            onClick={() => loginRivenditore(codice)}
          >
            <FormattedMessage
              id="btn_retailerlogin"
              defaultMessage="Accedi"
              description="Header Accedi"
            />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
