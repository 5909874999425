import {
  PropsWithChildren,
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Rivenditore } from "../models/rivenditore.model";

export const RivenditoreContext = createContext<{
  rivenditore?: Rivenditore;
  setRivenditore: (rivenditore: Rivenditore) => void;
  removeRivenditore: () => void;
}>({
  rivenditore: undefined,
  setRivenditore: (rivenditore: Rivenditore) => {},
  removeRivenditore: () => {}
});

export function RivenditoreProvider({ children }: PropsWithChildren) {
  const rivenditoreLocal = sessionStorage.getItem("rivenditore");
  let rivenditoreLocalDecoded = undefined;
  try {
    rivenditoreLocalDecoded = JSON.parse(rivenditoreLocal!);
  } catch (e) {}

  const [rivenditore, setRetailer] = useState<Rivenditore | undefined>(
    rivenditoreLocalDecoded
  );
  const setRivenditore = useCallback((rivenditore: Rivenditore) => {
    sessionStorage.setItem("rivenditore", JSON.stringify(rivenditore));
    setRetailer(rivenditore);
  }, []);
  const removeRivenditore = useCallback(() => {
    sessionStorage.removeItem("rivenditore");
    setRetailer(undefined);
  }, []);
  const value = useMemo(() => {
    return {
      rivenditore,
      setRivenditore,
      removeRivenditore,
    };
  }, [rivenditore, setRivenditore, removeRivenditore]);
  return (
    <RivenditoreContext.Provider value={value}>
      {children}
    </RivenditoreContext.Provider>
  );
}
