import ErrorIcon from "@mui/icons-material/Error";

export function PageError({ errorMessage }: { errorMessage: string }) {
  return (
    <div>
      <p className="flex justify-center items-center"><ErrorIcon /></p>
      <p className="text-xl font-semibold">{errorMessage}</p>
    </div>
  );
}
