import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Button, CircularProgress, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useLoginTicket } from "../services/ticket.service";

export default function SearchTicket() {
  const [email, setEmail] = useState("");
  const [touched, setTouched] = useState(false);
  const captchaRef = useRef<HCaptcha>(null);
  const [hCaptchaToken, setHCaptchaToken] = useState<undefined | string>(
    undefined
  );
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const { isLoading, mutateAsync, isError } = useLoginTicket();

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    setEmail(event.target.value);
  };

  const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    setCode(event.target.value);
  };

  function redirectTicket(email: string, code: string): void {
    if (!hCaptchaToken) {
      return;
    }
    mutateAsync({
      email,
      code: parseInt(code),
      captchaToken: hCaptchaToken,
    })
      .then((res) => {
        navigate("/ticket/" + res.ticketToken);
      })
      .catch((e) => {
        captchaRef.current?.resetCaptcha();
        console.error(e);
      });
  }

  return (
    <div className="bg-[#FFFFFF] w-full">
      <div className="container mx-auto py-8 px-4">
        <div className="flex justify-between flex-col sm:flex-row gap-y-4">
          <div className="">
            <p className="text-2xl font-semibold">
              <FormattedMessage
                id="fyr-title"
                defaultMessage="Stato ticket di assistenza"
                description="SearchTicket Stato ticket di assistenza"
              />
            </p>
            <p className="font-semibold">
              <FormattedMessage
                id="fyr-email"
                defaultMessage="Indirizzo e-mail associato alla richiesta"
                description="SearchTicket Indirizzo e-mail associato alla richiesta"
              />
            </p>
            <div className="mt-1">
              <TextField
                error={isError}
                fullWidth
                label="Email"
                variant="outlined"
                size="small"
                type="text"
                name="email"
                value={email}
                onTouchStart={() => setTouched(true)}
                onChange={handleChangeEmail}
              />
            </div>
            <p className="font-semibold mt-2">
              <FormattedMessage
                id="fyr-requestID"
                defaultMessage="Inserire il codice identificativo del ticket di assistenza (Es: 1000000)"
                description="SearchTicket Inserire il codice identificativo del ticket di assistenza (Es: 1000000)"
              />
            </p>
            <div className="mt-1">
              <TextField
                error={isError}
                fullWidth
                label="Codice"
                variant="outlined"
                size="small"
                type="number"
                name="code"
                value={code}
                onChange={handleChangeCode}
              />
            </div>
          </div>
          <p>{!hCaptchaToken && email.length > 0 && code.length > 0}</p>


          {touched && (
            <div className="flex justify-center flex-col">
              <HCaptcha
                ref={captchaRef}
                sitekey={process.env.REACT_APP_hCaptchaKey as string}
                onVerify={setHCaptchaToken}
                onExpire={() => setHCaptchaToken(undefined)}
              ></HCaptcha>
            </div>
          )}

          <div className="flex justify-center flex-col">
            <Button
              disabled={!hCaptchaToken || email === '' || code  === ''}
              sx={{
                "&.Mui-disabled": {
                  backgroundColor: "#b3b3b3 !important",
                  color: "white",
                },
              }}
              className="buttons-style"
              variant="contained"
              onClick={() => redirectTicket(email, code)}
            >
              {isLoading && <CircularProgress size={30} />}
              {!isLoading && (
                <FormattedMessage
                  id="fyr-btn-find"
                  defaultMessage="Visualizza la richiesta"
                  description="SearchTicket Visualizza la richiesta"
                />
              )}
            </Button>
            {/* {isError && (
              <p>
                <FormattedMessage
                  id="ticket-not-found"
                  defaultMessage="Ticket non trovato"
                  description="SearchTicket messaggio errore ticket non trovato"
                />
              </p>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}
