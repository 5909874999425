import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Divider, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { Fragment, useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { LangContext } from "../contexts/lang.context";
import { useListDevices } from "../services/device.service";
import { DeviceCard } from "./DeviceCard";
import { LazyImage } from "./LazyImage";
import { PageError } from "./PageError";

export default function SelectDevice() {
  const navigate = useNavigate();
  const { lang } = useContext(LangContext);
  const { tipologia, uid } = useParams();
  const { data: devices, error, isLoading } = useListDevices();

  const macroModels = useMemo(() => {
    if (!devices) {
      return [];
    }
    return devices?.sort((a, b) => a.macroModel.localeCompare(b.macroModel)).reduce((acc, curr) => {
      acc[curr.macroModelSlug] = curr.macroModel;
      return acc;
    }, {} as Record<string, string>);
  }, [devices]);
  const selectedItem = useMemo(
    () => devices?.find((one) => one.uid === uid),
    [uid, devices]
  );

  return (
    <div>
      {isLoading && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {!isLoading && !!error && (
        <div className="page-loading">
          <PageError errorMessage="Error" />
        </div>
      )}
      {devices && (
        <div className="container mx-auto py-10 px-4 contenitore-home">
          <p className="text-2xl pb-10">
            <FormattedMessage
              id="select-device_search-device_title"
              defaultMessage="Selezioni il dispositivo:"
              description="SelectDevice Selezioni il dispositivo:"
            />
          </p>
          <div className="inline-grid xl:grid-cols-11 grid-cols-4 gap-y-4 w-full">
            <div className="col-span-4 sm:col-span-3 xl:col-span-2">
              <List
                className="h-[28rem] w-full bg-[#FFFFFF] shadow-xl"
                sx={{
                  overflowY: "auto",
                }}
                subheader={
                  <ListSubheader
                    sx={{ background: "var(--theme-color)", color: "#FFFFFF" }}
                  >
                    <FormattedMessage
                      id="select-device_search-device_type_title"
                      defaultMessage="Tipologia:"
                      description="SelectDevice Tipologia:"
                    />
                  </ListSubheader>
                }
              >
                <Divider />
                {Object.entries(macroModels).map(([macroSlug, macroName]) => (
                  <div key={macroSlug + macroName}>
                    <ListItemButton
                      
                      selected={tipologia === macroSlug}
                      onClick={() => {
                        navigate(`/dispositivi/` + macroSlug);
                      }}
                    >
                      <ListItemText primary={macroName} />
                    </ListItemButton>
                    <Divider />
                  </div>
                ))}
              </List>
            </div>

            {tipologia && (
              <>
                <div className="flex justify-center place-items-center col-span-4 sm:col-span-1">
                  <KeyboardArrowRightIcon
                    className="rotate-90 sm:rotate-0"
                    sx={{ color: "#1D4EBB", fontSize: "80px" }}
                  />
                </div>
                <div className="col-span-4 sm:col-span-3">
                  <List
                    className="h-[28rem] w-full bg-[#FFFFFF] shadow-xl"
                    sx={{
                      overflowY: "auto",
                    }}
                    subheader={
                      <ListSubheader
                        sx={{ background: "#1D4EBB", color: "#FFFFFF" }}
                      >
                        <FormattedMessage
                          id="select-device_search-device_model_title"
                          defaultMessage="Selezioni un modello:"
                          description="SelectDevice Selezioni un modello:"
                        />
                      </ListSubheader>
                    }
                  >
                    <Divider />
                    {devices
                      .filter((one) =>
                        one.countries.find((country) => country === lang)
                      )
                      .filter((one) => one.macroModelSlug === tipologia)
                      .map((item) => (
                        <div key={item.uid}>
                          <ListItemButton
                            selected={selectedItem?.uid === item.uid}
                            onClick={() =>
                              navigate(
                                `/dispositivi/${item.macroModelSlug}/${item.uid}`
                              )
                            }
                          >
                            <p className="mr-4">
                              <LazyImage
                                src={
                                  item.assets?.find(
                                    (one) => one.type === "device"
                                  )?.path
                                }
                                alt="Immagine dispositivo"
                                width={100}
                                height={60}
                              />
                            </p>
                            <ListItemText
                              primary={item.title}
                              secondary={
                                <Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    cod. {item.code} {item.macroModel}
                                  </Typography>
                                </Fragment>
                              }
                            />
                          </ListItemButton>
                          <Divider />
                        </div>
                      ))}
                  </List>
                </div>
              </>
            )}
            {selectedItem && <DeviceCard device={selectedItem}></DeviceCard>}
          </div>
        </div>
      )}
    </div>
  );
}
