import { Button } from "@mui/material";
import { Device } from "../models/device.model";
import { LazyImage } from "./LazyImage";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import HelpIcon from "@mui/icons-material/Help";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ContactDialog } from './ContactDialog';
import { FormattedMessage } from "react-intl";

export function DeviceCard({ device }: { device: Device }) {
  const navigate = useNavigate();
  const selectedItem = device;

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="flex justify-center place-items-center col-span-4 sm:col-span-1">
        <KeyboardDoubleArrowRightIcon
          className="rotate-90 sm:rotate-0"
          sx={{ color: "#1D4EBB", fontSize: "80px" }}
        />
      </div>
      <div className="col-span-4 sm:col-span-3 xl:col-span-4">
        <div className="py-4 px-8 w-full sm:h-[28rem] bg-[#FFFFFF] shadow-xl">
          <p className="text-center text-xl font-semibold mb-8">
            {selectedItem.title}
          </p>
          <div className="flex flex-col sm:flex sm:flex-row sm:justify-between">
            <div className="mr-5 flex flex-col items-center">
              {selectedItem.assets?.find((one) => one.type === 'device')?.path && (
                <LazyImage
                  src={selectedItem.assets?.find((one) => one.type === 'device')?.path}
                  alt="Immagine dispositivo"
                />
              )}
              <LazyImage
                src={selectedItem.assets?.find((one) => one.type === 'pack')?.path}
                alt="Immagine packaging"
              />
              <p className="font-semibold mt-4">EAN: {selectedItem.ean}</p>
            </div>
            <div className="flex justify-center items-center flex-col ml-5">
              <Link
                to={`/dispositivi/${selectedItem.macroModelSlug}/${selectedItem.uid}/faq`}
              >
                <Button
                  className="buttons-style margin-bottom-jef"
                  variant="contained"
                >
                  <HelpIcon className="icon-button" />
                  <span className="w-full">FAQ</span>
                </Button>
              </Link>
              <Button
                className="buttons-style margin-bottom-jef"
                variant="contained"
                onClick={() => {
                  navigate(
                    `/dispositivi/${selectedItem.macroModelSlug}/${selectedItem.uid}/manuali`
                  );
                }}
              >
                <MenuBookIcon className="icon-button" />
                <span className="w-full"><FormattedMessage
                  id="select-device_menu_manuals"
                  defaultMessage="Manuali"
                  description="DeviceCard Manuali"
                /></span>
              </Button>
              <Button
                className="buttons-style margin-bottom-jef"
                variant="contained"
                onClick={() => {
                  navigate(
                    `/dispositivi/${selectedItem.macroModelSlug}/${selectedItem.uid}/ticket-assistenza`
                  );
                }}
              >
                <AssignmentIcon className="icon-button" />
                <span className="w-full"><FormattedMessage
                  id="select-device_menu_request"
                  defaultMessage="Ticket di assistenza"
                  description="DeviceCard Ticket di assistenza"
                /></span>
              </Button>
              <Button
                className="buttons-style"
                variant="contained"
                onClick={handleClickOpen}
              >
                <ContactPhoneIcon className="icon-button" />
                <span className="w-full"><FormattedMessage
                  id="select-device_menu_contactus"
                  defaultMessage="Contatti"
                  description="DeviceCard Contatti"
                /></span>
              </Button>
              <ContactDialog
                onClose={handleClose}
                open={open}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
