import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ResPostTicket } from "../models/ticket.model";

interface TicketAssistenzaInviatoProps {
  open: boolean;
  onClose: () => void;
  ticket: ResPostTicket;
}

export function TicketAssistenzaInviato({
  onClose,
  open,
  ticket,
}: TicketAssistenzaInviatoProps) {
  return (
    <Dialog keepMounted={true} onClose={onClose} open={open}>
      <DialogTitle className="background-blue-clem text-white">
        <FormattedMessage
          id="ticket-creato"
          defaultMessage="Ticket Creato"
          description="TicketAssistenzaInviato Ticket Creato"
        />
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          <div className="py-2">
            <p className="font-semibold">* {ticket.name} 
              <FormattedMessage
                id="form_notification_success_text"
                defaultMessage=" *, la sua richiesta è stata inviata correttamente."
                description="TicketAssistenzaInviato text"
              />
            </p>
          </div>
        </Typography>
        <Typography gutterBottom>
          <div className="py-2 mb-4 text-center">
            <p>
              <FormattedMessage
                id="faq_popup_contact_sent_success_ticket"
                defaultMessage="Codice Ticket"
                description="TicketAssistenzaInviato Codice Ticket"
              />: {ticket.detail.Clementoni_request_ID}
            </p>
          </div>
        </Typography>
        <Link to=".." relative="path">
          <Button className="buttons-style" variant="contained">
            <ArrowCircleLeftIcon className="icon-button" />{" "}
            <span className="w-full">
              <FormattedMessage
                id="aftersale_backlink"
                defaultMessage="Torna indietro"
                description="TicketAssistenzaInviato Torna indietro"
              />
            </span>
          </Button>
        </Link>
      </DialogContent>
    </Dialog>
  );
}
