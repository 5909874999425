export function getAuthHeaders() {
    return {
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
    }
}

export function authFetch(input: RequestInfo | URL, init?: RequestInit) {
    return fetch(input, {
        ...init,
        headers: {
            ...getAuthHeaders(),
            ...init?.headers
        }
    });
}



export const fetchJson = <T>(input: RequestInfo | URL, init?: RequestInit | undefined): Promise<T> => fetch(input, init)
    .then(
        async response => {
            try {
                const responseBody = await response.json();
                if (!response.ok) {
                    throw responseBody;
                }
                return responseBody as T
            } catch (e) {
                throw e;
            }
        }
    );