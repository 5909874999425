import logo from "./Clementoni logo - colori - negativo.png";

export default function Footer() {
  return (
    <div className="footer bg-blue-clem-500 border-t-8 border-white">
      <div className="container mx-auto">
        <div className="grid place-content-center text-center">
        <a href="/">
              <img src={logo} alt="Clementoni" className="logo-style"></img>
            </a>
          <p className="text-white">
            © CLEMENTONI - P.IVA N. 00092380435 |{" "}
            <a
              className="link-footer font-semibold"
              href="https://it.clementoni.com/"
              target="_blank" 
              rel="noreferrer"
            >
              www.clementoni.com
            </a>{" "}
            |{" "}
            <a
              className="link-footer"
              href="https://assets.clempad.clementoni.com/pdf/Privacy_Policy_IT_EN.pdf"
              target="_blank" 
              rel="noreferrer"
            >
              Privacy
            </a>{" "}
            |{" "}
            <a
              className="link-footer"
              href="https://assets.clempad.clementoni.com/pdf/IT_v1.pdf"
              target="_blank" 
              rel="noreferrer"
            >
              Informazioni sulla sicurezza
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
