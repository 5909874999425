import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Manual } from "../models/manual.model";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { LangContext } from "../contexts/lang.context";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1D4EBB",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#FFFFFF",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last borderr
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export function TableManuals({ manuals }: { manuals: Manual[] }) {
  const { lang } = useContext(LangContext);
  return (
    <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left"></StyledTableCell>
            <StyledTableCell>Title</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {manuals
            .filter((manual) => manual.published === true)
            .filter((rawManual) => lang in rawManual.translations)
            .map((row) => (
              <StyledTableRow key={row.uid}>
                <StyledTableCell align="left">
                  <PictureAsPdfIcon sx={{ color: "#757575" }} />
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  <p className="text-lg">{row.translations[lang].title}</p>
                  <p>{row.translations[lang].subtitle}</p>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Link to={row.translations[lang].manual_path} relative="path">
                    <Button
                      variant="text"
                      className="buttons-edit-delete-style"
                    >
                      <CloudDownloadIcon sx={{ color: "#757575" }} />
                    </Button>
                  </Link>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
